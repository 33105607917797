import React from "react";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import { Link, useNavigate } from "react-router-dom";
import routes from "constants/routes";
import AuthFormField from "components/auth/authFormField/authFormField";
import { registerForProgram } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import "./SignUp.scss";
import Button from "components/button/Button";
import { notifications } from "helpers/notifications";
import RegistrationCheckbox from "../formFields/registrationCheckbox/registrationCheckbox";

const SignUpForm = ({ programCode, programId }) => {
	const { passwordFormatWrong } = notifications;
	const dispatch = useDispatch();
	const { SIGN_IN } = routes;
	const { registerForProgramInProgress } = useSelector(
		(state) => state.programs
	);
	const navigate = useNavigate();

	return (
		<Formik
			initialValues={{
				name: "",
				surname: "",
				email: "",
				password: "",
				privacy: "",
			}}
			validationSchema={Yup.object({
				name: Yup.string().required("Required"),
				surname: Yup.string().required("Required"),
				email: Yup.string().email("Invalid email address").required("Required"),
				password: Yup.string()
					.required("Required")
					.matches(
						/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{8,}$/,
						passwordFormatWrong
					),
				privacy: Yup.string().required("Required"),
			})}
			onSubmit={(values) => {
				const requestParams = {
					...values,
					password_confirmation: values.password,
					code: programCode,
					id: programId,
					email: values.email.toLowerCase(),
				};
				dispatch(registerForProgram(requestParams, () => navigate("/")));
			}}
		>
			<Form className="sign-up" noValidate={true}>
				<Field
					name="name"
					label="First Name"
					type="text"
					placeholder="Enter first name..."
					className="sign-up__input"
					component={AuthFormField}
				/>
				<Field
					name="surname"
					label="Last Name"
					type="text"
					placeholder="Enter last name..."
					className="sign-up__input"
					component={AuthFormField}
				/>
				<Field
					name="email"
					type="email"
					label="Email Address"
					placeholder="email.example@gmail.com..."
					className="sign-up__input"
					component={AuthFormField}
				/>
				<Field
					name="password"
					type="password"
					label="Password"
					placeholder="Please enter your password..."
					className="sign-up__input"
					component={AuthFormField}
				/>
				<Field name="privacy" component={RegistrationCheckbox} />
				<div className="sign-up__bottom">
					<Button
						type="submit"
						className="sign-up__submit"
						loading={registerForProgramInProgress}
						disabled={registerForProgramInProgress}
					>
						Register
					</Button>
					<p className="sign-up__bottom-text">
						Already have an account?{" "}
						<Link
							to={{
								pathname: SIGN_IN,
								search:
									programCode &&
									programId &&
									`?code=${programCode}&id=${programId}`,
							}}
							className="sign-up__login-link"
						>
							Login
						</Link>
					</p>
				</div>
			</Form>
		</Formik>
	);
};

export default SignUpForm;

import React, { useEffect, useState } from "react";
import ResetPasswordForm from "components/forms/resetPasswordForm/resetPasswordForm";
import { ReactComponent as LinkArrow } from "assets/vector/auth-page/return-arrow.svg";
import { Link, useLocation } from "react-router-dom";
import routes from "constants/routes";
import CheckEmail from "components/auth/resetPassword/checkEmail/checkEmail";
import { useSelector } from "react-redux";
import Notification from "components/auth/notification/Notification";

const ResetPassword = () => {
	const { SIGN_IN } = routes;
	const [isErrorNotification, setErrorNotification] = useState(false);
	const { resetPasswordSuccess, resetPasswordError, resetPasswordInProgress } =
		useSelector((state) => state.setPassword);
	const urlSearchParams = new URLSearchParams(useLocation().search);
	const programCode = urlSearchParams.get("code");
	const programId = urlSearchParams.get("id");

	useEffect(() => {
		let timedNotification;

		if (resetPasswordError && !resetPasswordInProgress) {
			timedNotification = setTimeout(() => setErrorNotification(false), 5000);
			setErrorNotification(true);
		} else if (resetPasswordInProgress) {
			clearTimeout(timedNotification);
			setErrorNotification(false);
		}
	}, [resetPasswordError, resetPasswordInProgress]);

	return resetPasswordSuccess ? (
		<CheckEmail />
	) : (
		<>
			<Link
				to={{
					pathname: SIGN_IN,
					search:
						programCode && programId && `?code=${programCode}&id=${programId}`,
				}}
				className="default-auth__return-link"
			>
				<LinkArrow className="default-auth__return-icon" />
				Back
			</Link>
			<h1 className="default-auth__title default-auth__title--reset">
				<Notification show={isErrorNotification}>
					{resetPasswordError}
				</Notification>
				Reset Password
			</h1>
			<p className="default-auth__info-text">
				Enter your email and we will send you instructions to reset password
			</p>
			<ResetPasswordForm />
		</>
	);
};

export default ResetPassword;

import React, { useRef, useState, useEffect } from "react";
import "./userInformation.scss";
import { ReactComponent as DefaultAvaSVG } from "assets/vector/header/default-ava.svg";
import { ReactComponent as Circle } from "assets/vector/notification/red-circle.svg";
import { ReactComponent as BellSVG } from "assets/vector/bell.svg";
import Chat from "../../../Chat/Chat";
import SiteNotification from "../../../notification/SiteNotification/SiteNotification";
import { lock, unlock } from "tua-body-scroll-lock";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import routes from "constants/routes";
import { useMediaQuery } from "react-responsive";
import mediaQuery from "constants/mediaQuery";
import Pusher from "pusher-js";
import CustomPlatformSettings from "services/platformSettings/platformSettings";
import { clearLocalStorage } from "services/auth";
import {
	logout,
	getListRooms,
	getListUsers,
	sendReadNotifications,
	successChatConnection,
	getMessagesFromUser,
	setChatOpen,
	setChatUser,
	setIsLeadCoachDashboardPage,
} from "../../../../redux/actions";
import useClickOutside from "../../../../hooks/useClickOutside";

const { MOBILE_QUERY_767 } = mediaQuery;

export default function UserInformation() {
	const navigate = useNavigate();

	const getCurrentUserInfo = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const getListRoomsChat = useSelector((state) => state.chat.listRooms);
	const { authToken } = useSelector((state) => state.auth);
	const { showDashboardPage } = useSelector((state) => state.dashboard);
	const { isChatOpen, chatUser } = useSelector((state) => state.chat);
	const { isLeadCoachDashboardPage } = useSelector((state) => state.dashboard);

	const chatConnection = useSelector((state) => state.auth.loadChat);

	let authEndpoint = process.env.REACT_APP_BROADCASTING_URL;
	let apiKey = process.env.REACT_APP_BROADCASTING_API_KEY;

	const dispatch = useDispatch();
	const dropdownRef = useRef(null);
	const dropdownRefMobile = useRef(null);
	const [isMenuOpened, setIsMenuOpened] = useState(false);
	const [isMenuOpenedMobile, setIsMenuOpenedMobile] = useState(false);

	const [newMessage, setNewMessage] = useState(false);
	const [messageSenderUserID, setMessageSenderUserID] = useState({});
	const [isNotificationOpened, setIsNotificationOpened] = useState(false);
	const countNotifications = useSelector(
		(state) => state.notification.countNotifications
	);
	const { MY_PROFILE, MY_SETTINGS, SIGN_IN, DASHBOARD } = routes;

	const { currentUserInfo } = useSelector((state) => state.currentUserInfo);

	const isMobile = useMediaQuery({ maxDeviceWidth: MOBILE_QUERY_767 });

	const school_program_id = localStorage.getItem("school_program_id");
	const siteNotificationRef = useRef();
	const notificationIconRef = useRef();
	const newMessageIconRef = useRef();
	const chatContentRef = useRef();

	const ProfileMenuShowToggleMobile = () => {
		setIsMenuOpenedMobile(!isMenuOpenedMobile);
	};

	const onLogoutClick = () => {
		dispatch(logout());
		clearLocalStorage();
		if (school_program_id) {
			localStorage.removeItem("school_program_id");
		}
		CustomPlatformSettings();
		navigate(SIGN_IN);
		localStorage.removeItem("dashboard_user_program_id");
		localStorage.removeItem("dashboard_user_program_name");
		localStorage.removeItem("isLeadCoachDashboardPage");
	};

	function Bell() {
		setIsNotificationOpened(!isNotificationOpened);
		if (isChatOpen) {
			dispatch(setChatOpen(!isChatOpen));
		}
	}

	function Message() {
		dispatch(setChatOpen(!isChatOpen));
		if (isNotificationOpened) {
			setIsNotificationOpened(false);
		}
	}

	useEffect(() => {
		if (isNotificationOpened) {
			lock(document);
			dispatch(sendReadNotifications());
		} else {
			unlock(document);
		}
	}, [isNotificationOpened]);

	useEffect(() => {
		if (getCurrentUserInfo && chatConnection) {
			const pusher = new Pusher(apiKey, {
				// always must be 'eu' for this project
				cluster: "eu",
				disableStats: true,
				// false for local, true for staging and prod
				forceTLS: false,
				authEndpoint: authEndpoint,
				auth: {
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
					accept: "application/json",
				},
			});
			Pusher.logToConsole = false;

			// Subscribing to chanel
			const channel = pusher.subscribe(
				`private-App.User.${getCurrentUserInfo.id}`
			);

			if (getCurrentUserInfo.role === "school_admin") {
				// used to optimize the loading of the Dashboard page for school admins by prioritizing the order of requests

				if (showDashboardPage) {
					channel.bind("message", function (data) {
						setMessageSenderUserID({
							senderUserID: data.body.user_id,
							roomID: data.roomId,
						});

						dispatch(getListRooms());
					});

					channel.bind("pusher:subscription_error", function (data) {
						console.log(`${data.type}: ${data.error}`);
					});
				}
			} else {
				channel.bind("message", function (data) {
					setMessageSenderUserID({
						senderUserID: data.body.user_id,
						roomID: data.roomId,
					});

					dispatch(getListRooms());
				});

				channel.bind("pusher:subscription_error", function (data) {
					console.log(`${data.type}: ${data.error}`);
				});
			}
			dispatch(successChatConnection());
		}
	}, [getCurrentUserInfo, showDashboardPage]);

	useEffect(() => {
		if (isChatOpen) {
			dispatch(getListRooms());
			dispatch(getListUsers());
			lock(document);
		} else {
			unlock(document);
			dispatch(setChatUser(null));
		}
	}, [isChatOpen]);

	useEffect(() => {
		if (getCurrentUserInfo?.role === "school_admin") {
			// used to optimize the loading of the Dashboard page for school admins by prioritizing the order of requests
			if (showDashboardPage) {
				dispatch(getListRooms());
				dispatch(getListUsers());
			}
		} else {
			dispatch(getListRooms());
			dispatch(getListUsers());
		}
	}, [getCurrentUserInfo, showDashboardPage]);

	useEffect(() => {
		if (getListRoomsChat) {
			const unreadListRooms = getListRoomsChat.map((room) => {
				return room.has_unread;
			});

			setNewMessage(unreadListRooms.includes(true));
		}
	}, [getListRoomsChat]);

	useEffect(() => {
		if (chatUser && chatUser.id === messageSenderUserID.senderUserID) {
			dispatch(getMessagesFromUser(messageSenderUserID.roomID));
		}
	}, [messageSenderUserID]);

	useClickOutside([siteNotificationRef, notificationIconRef], () => {
		if (isNotificationOpened) setIsNotificationOpened(false);
	});

	useClickOutside([newMessageIconRef, chatContentRef], () => {
		if (isChatOpen) dispatch(setChatOpen(false));
	});

	useClickOutside(dropdownRefMobile, () => {
		setIsMenuOpenedMobile(false);
	});

	useClickOutside(dropdownRef, () => {
		setIsMenuOpened(false);
	});

	const handleSetLeadDashboardPage = () => {
		dispatch(setIsLeadCoachDashboardPage(!isLeadCoachDashboardPage));
	};

	return (
		<div className="header-user-information">
			<div
				className={`notification-block ${isNotificationOpened ? `opened` : ``}`}
			>
				<BellSVG
					className={`bell-img  ${
						isNotificationOpened ? `notification-open` : ``
					}`}
					onClick={Bell}
					ref={notificationIconRef}
				/>
				{countNotifications && countNotifications > 0 ? (
					<Circle className={`circle-notification`} />
				) : (
					""
				)}
			</div>
			<div
				className={`container-site-notification ${
					isNotificationOpened
						? `site-notification-open`
						: `site-notification-close`
				}`}
			>
				<SiteNotification ref={siteNotificationRef} />
			</div>
			<div
				className={`icon-chat ${newMessage && `new-message`} ${
					isChatOpen ? `open-chat` : ``
				}`}
				onClick={(e) => {
					e.stopPropagation();
					Message();
				}}
				ref={newMessageIconRef}
			>
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M2.00003 11.4445C1.99621 12.911 2.33884 14.3577 3.00003 15.6667C3.78399 17.2353 4.98919 18.5546 6.48064 19.477C7.97208 20.3993 9.69086 20.8882 11.4445 20.8889C12.911 20.8927 14.3577 20.5501 15.6667 19.8889L22 22L19.8889 15.6667C20.5501 14.3577 20.8927 12.911 20.8889 11.4445C20.8882 9.69086 20.3993 7.97208 19.477 6.48064C18.5546 4.98919 17.2353 3.78399 15.6667 3.00003C14.3577 2.33884 12.911 1.99621 11.4445 2.00003H10.8889C8.57297 2.1278 6.38553 3.10532 4.74543 4.74543C3.10532 6.38553 2.1278 8.57297 2.00003 10.8889V11.4445Z"
						stroke="#212121"
						strokeWidth="2"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<ellipse cx="7.94573" cy="11.5" rx="0.945732" ry="1" fill="#212121" />
					<ellipse cx="11.35" cy="11.5" rx="0.945732" ry="1" fill="#212121" />
					<ellipse cx="14.7543" cy="11.5" rx="0.945732" ry="1" fill="#212121" />
				</svg>
				{newMessage && <Circle className={`circle-notification`} />}
			</div>
			<div
				className={`container-chat  ${isChatOpen ? `open-chat` : `close-chat`}`}
			>
				<Chat
					getListRoomsChat={getListRoomsChat}
					chatUser={chatUser}
					ref={chatContentRef}
				/>
			</div>
			{isMobile ? (
				<div
					className={`user-avatar ${isMenuOpenedMobile ? `show` : `hide`}`}
					onClick={ProfileMenuShowToggleMobile}
					ref={dropdownRefMobile}
				>
					{currentUserInfo && currentUserInfo.avatar ? (
						<img
							className={`user-avatar-image`}
							src={currentUserInfo.avatar}
							alt="avatar"
						/>
					) : (
						<DefaultAvaSVG className={`user-avatar-image`} alt="avatar" />
					)}
					<ul
						className={`profile-menu-list ${
							isMenuOpenedMobile ? `show` : `hide`
						}`}
					>
						<li className={`profile-menu-item`}>
							<Link className="link-to-page" to={MY_PROFILE}>
								Profile
							</Link>
						</li>
						<li className={`profile-menu-item`}>
							<Link className="link-to-page" to={MY_SETTINGS}>
								Settings
							</Link>
						</li>
						{getCurrentUserInfo?.is_lead_coach && (
							<li
								className={`profile-menu-item text-sm`}
								onClick={handleSetLeadDashboardPage}
							>
								<Link className="link-to-page" to={DASHBOARD}>
									{isLeadCoachDashboardPage ? "Coach" : "Lead Coach"}
								</Link>
							</li>
						)}
						<li className={`log-out`} onClick={onLogoutClick}>
							Log out
						</li>
					</ul>
				</div>
			) : (
				<div
					className={`user-avatar ${isMenuOpened ? `show` : `hide`}`}
					onClick={() => setIsMenuOpened(!isMenuOpened)}
					ref={dropdownRef}
				>
					{currentUserInfo && currentUserInfo.avatar ? (
						<img
							className={`user-avatar-image`}
							src={currentUserInfo.avatar}
							alt="avatar"
						/>
					) : (
						<DefaultAvaSVG className={`user-avatar-image`} alt="avatar" />
					)}
					<ul className={`profile-menu-list ${isMenuOpened ? `show` : `hide`}`}>
						<li className={`profile-menu-item`}>
							<Link className="link-to-page" to={MY_PROFILE}>
								Profile
							</Link>
						</li>
						<li className={`profile-menu-item`}>
							<Link className="link-to-page" to={MY_SETTINGS}>
								Settings
							</Link>
						</li>
						{getCurrentUserInfo.is_lead_coach && (
							<li
								className={`profile-menu-item`}
								onClick={handleSetLeadDashboardPage}
							>
								<Link className="link-to-page" to={DASHBOARD}>
									{isLeadCoachDashboardPage ? "Coach" : "Lead Coach"}
								</Link>
							</li>
						)}
						<li className={`log-out`} onClick={onLogoutClick}>
							Log out
						</li>
					</ul>
				</div>
			)}
		</div>
	);
}
// DASHBOARD

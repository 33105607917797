import React from "react";
import { Routes, Route } from "react-router-dom";
import { privateRoutes as mainRoutes } from "./routes";
import Auth from "components/pages/Auth/Auth";
import { PrivateRoute } from "../PrivateRoute/PrivateRoute";
import { useAppInit } from "../../hooks/useAppInit";
import InitLoader from "components/auth/initLoader/initLoader";

export default function Root() {
	const isAppInited = useAppInit();

	return isAppInited ? (
		<Routes>
			<Route path="/" element={<PrivateRoute />}>
				{mainRoutes.map((route) => (
					<Route
						key={route.path}
						path={route.path}
						element={<route.component />}
					/>
				))}
			</Route>
			<Route path="/*" element={<Auth />} />
		</Routes>
	) : (
		<InitLoader />
	);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	DASHBOARD: "/",
	PARTICIPANTS: "/participants",
	COACHES: "/coaches",
	COACH_PROFILE: "/coaches/:profileId",
	PROGRAMMES: "/programmes",
	CALENDAR: "/calendar",
	EVALUATIONS: "/evaluations",
	REGISTRATION_PAGE: "/registration",
	MY_PROFILE: "/my-profile",
	EDIT_MY_PROFILE: "/my-profile/edit",
	MY_SETTINGS: "/settings",
	REPORTS_PAGE: "/reports",
	SINGLE_REPORT: "/reports/:id",
	REGISTER_PROGRAMS: "/register-for-program",
	REGISTER_FOR_PROGRAM: "/register-for-program/:code/:id",
	SIGN_IN: "/login",
	RESET_PASSWORD: "/reset-password",
	CREATE_PASSWORD: "/create-password",
	CAREER_QUESTIONNAIRE: "/career-questionnaire",
	QUESTIONNAIRE: "/questionnaire",
	QUESTIONNAIRE_FORM: "/questionnaire-form",
	QUESTIONNAIRE_COACH_MATCHING: "/coach-matching",
	MATCHED_COACH_PROFILE: "/coach-matching/:profileId",
	FEEDBACK_PAGE: "/feedback",
	PROGRAMME_STARTING: "/programme-starting",
	DECISION_FORM: "/decision-form",
	NOT_FOUND: "/404",
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import pageTitles from "constants/pageTitles";
import Spinner from "components/Spinner/Spinner";
import PieChartComponent from "./PieChartComponent/PieChartComponent";
import ProgramsDropdown from "./programsDropdown/programsDropdown";
import Ratings from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/Ratings";
import Attendance from "components/pages/Dashboard/SchoolAdmin/Charts/Attendance/Attendance";
import TotalCredits from "./Charts/TotalCredits/TotalCredits";
import SchoolOverview from "./SchoolOverview/SchoolOverview";
import TotalStudents from "./TotalStudents/TotalStudents";
import {
	updatePageTitle,
	getSessions,
	getAverageMark,
	getParticipants,
	getAttendanceRate,
	getListUserSchoolPrograms,
	getSessionsCredits,
} from "../../../../redux/actions";
import {
	numberOfSessionsChartData,
	participantsSatisfactionChartData,
	sessionFrequencyData,
} from "./helpers/pieChartsData";

import "./Dashboard.scss";

export default function DashboardSchoolAdmin() {
	const { DASHBOARD_TITLE } = pageTitles;

	const [currentProgram, setCurrentProgram] = useState();

	const dispatch = useDispatch();
	const get_dashboard_user_program = localStorage.getItem(
		"dashboard_user_program"
	);

	const listUserSchoolPrograms = useSelector(
		(state) => state.dashboard.listUserSchoolPrograms
	);
	const dashboardSessions = useSelector((state) => state.dashboard.sessions);
	const dashboardSessionsCredits =
		useSelector((state) => state.dashboard.sessionsCredits) || {};
	const getCurrentSchoolProgram = useSelector(
		(state) => state.dashboard.currentSchoolProgram
	);
	const attendance = useSelector((state) => state.dashboard.attendance);
	const showDashboardPage = useSelector(
		(state) => state.dashboard.showDashboardPage
	);
	const participantsRating =
		useSelector((state) => state.dashboard.participants) || {};

	const { isLeadCoachDashboardPage } = useSelector((state) => state.dashboard);

	useEffect(() => {
		dispatch(updatePageTitle(DASHBOARD_TITLE));

		if (!getCurrentSchoolProgram) {
			dispatch(
				getListUserSchoolPrograms({
					take: 10,
					offset: 0,
				})
			);
		}
	}, []);

	useEffect(() => {
		if (getCurrentSchoolProgram?.id) {
			if (
				getCurrentSchoolProgram.id ===
					JSON.parse(get_dashboard_user_program)?.id ||
				!JSON.parse(get_dashboard_user_program)?.id
			) {
				dispatch(getSessionsCredits(getCurrentSchoolProgram?.id));
				dispatch(getSessions(getCurrentSchoolProgram?.id));
				dispatch(getAverageMark(getCurrentSchoolProgram?.id));
				dispatch(getParticipants(getCurrentSchoolProgram?.id));
				dispatch(getAttendanceRate(getCurrentSchoolProgram?.id));
				setCurrentProgram(
					listUserSchoolPrograms.find(
						(program) => program.id === getCurrentSchoolProgram?.id
					)
				);
			} else {
				dispatch(
					getSessionsCredits(JSON.parse(get_dashboard_user_program)?.id)
				);
				dispatch(getSessions(JSON.parse(get_dashboard_user_program)?.id));
				dispatch(getAverageMark(JSON.parse(get_dashboard_user_program)?.id));
				dispatch(getParticipants(JSON.parse(get_dashboard_user_program)?.id));
				dispatch(getAttendanceRate(JSON.parse(get_dashboard_user_program)?.id));
				setCurrentProgram(
					listUserSchoolPrograms.find(
						(program) =>
							program.id === JSON.parse(get_dashboard_user_program)?.id
					)
				);
			}
		}
	}, [getCurrentSchoolProgram, get_dashboard_user_program]);

	return (
		<section className="section-wrap page-dashboard">
			<div
				className="program-overview__wrapper"
				style={isLeadCoachDashboardPage ? { width: "100%" } : {}}
			>
				<div className="overview__title">
					<span>Programme overview</span>
					{getCurrentSchoolProgram?.is_deactivated && (
						<div className="program-status">Completed</div>
					)}
				</div>
				<div className="program-overview">
					{showDashboardPage &&
					attendance &&
					participantsRating &&
					dashboardSessions ? (
						<>
							<div className="top__section">
								<div className="left__side">
									<ProgramsDropdown />
									{currentProgram && (
										<TotalStudents
											total={currentProgram.participants?.total}
											active={currentProgram.participants?.active}
										/>
									)}
								</div>
								<TotalCredits credits={dashboardSessionsCredits} />
							</div>
							<div className={`charts-list`}>
								<PieChartComponent
									title="Number of sessions"
									chartTitle={`${dashboardSessions.number_of_sessions?.completed_percent}%`}
									chartSubtitle={
										<>
											Used
											<br />
											sessions
										</>
									}
									pieChartData={numberOfSessionsChartData(
										dashboardSessions
									).reverse()}
									statisticData={numberOfSessionsChartData(dashboardSessions)}
									total={
										<>
											<span>
												{dashboardSessions.number_of_sessions?.sessions_total}
											</span>{" "}
											Sessions total
										</>
									}
								/>
								<PieChartComponent
									title="Participants satisfaction"
									chartTitle={`${dashboardSessions.participant_satisfaction?.amazing_percent}%`}
									chartSubtitle="Satisfied"
									pieChartData={participantsSatisfactionChartData(
										dashboardSessions
									).reverse()}
									statisticData={participantsSatisfactionChartData(
										dashboardSessions
									)}
									total={
										<>
											<span>
												{
													dashboardSessions.participant_satisfaction
														?.feedback_forms_total
												}
											</span>{" "}
											Feedback forms submitted
										</>
									}
								/>
								<PieChartComponent
									title="Session frequency"
									chartTitle={dashboardSessions.session_frequency?.avg_days}
									chartSubtitle="Avg. days"
									pieChartData={sessionFrequencyData(dashboardSessions)}
									statisticData={sessionFrequencyData(dashboardSessions)}
									frequency={true}
									total={
										<>
											<span>
												{
													dashboardSessions.session_frequency
														?.participants_count
												}
											</span>{" "}
											Calculated participants programs
										</>
									}
								/>
							</div>
							<Attendance
								sessions={attendance?.sessions}
								additional={attendance?.additional_sessions}
							/>
							<Ratings rating={participantsRating} />
						</>
					) : (
						<>
							<Spinner size={72} />
						</>
					)}
				</div>
			</div>
			{!isLeadCoachDashboardPage && <SchoolOverview />}
		</section>
	);
}

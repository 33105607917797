import React from "react";
import { Link } from "react-router-dom";
import defaultAva from "assets/vector/default-avatar.svg";
import { useNavigate } from "react-router-dom";

const ParticipantProfilePreview = ({ program }) => {
	const navigate = useNavigate();

	const handleViewProfileClick = async (event) => {
		event.preventDefault();
		event.stopPropagation();
		navigate(`/programmes/notes/${program.id}`);
	};

	return (
		<Link
			className="view-profile__card"
			to={`/programmes/single-program/${program.id}`}
		>
			<div
				className={`item gap-6 ${program.is_deactivated ? "deactivated" : ""}`}
			>
				<div className="participant-avatar">
					<img
						src={
							program.participant.avatar
								? program.participant.avatar
								: defaultAva
						}
						alt=""
					/>
				</div>
				<div className="participant-short-info">
					<h3 className={`name`}>
						<span>{program.participant.name}</span>
						{program.is_deactivated && (
							<div className="deactivated-label">Deactivated</div>
						)}
					</h3>
					<div className="participant-block-wrapper">
						<div className="participant-left-block">
							<p className={`country`}> {program.participant.program_name}</p>
							<p className="program-in-progress">
								{program.participant.school_name}
							</p>
						</div>
						<div className="participant-right-block">
							<div className="sessions">
								<div className="counter">
									<span className={`from`}>
										{program.participant.completed_session_activities}
									</span>
									<span className={`to`}>
										{" "}
										of {program.participant.total_session_activities} sessions
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="flex items-center flex-1 gap-6 xl:gap-0">
					<button
						className="w-max button bg-blue-500 hover:bg-blue-600 py-[11px] px-[10px] text-white font-bold  shrink-0"
						onClick={handleViewProfileClick}
					>
						View notes
					</button>
					<div className="participant-full-info whitespace-nowrap">
						<button className="view-profile">View programme</button>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default ParticipantProfilePreview;

import { combineReducers } from "redux";
import modalReducer from "./modal";
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import currentPageTitleReducer from "./currentPageTitle";
import setPasswordReducer from "./setPassword";
import registerForProgramReducer from "./registerForProgram";
import personalInfoReducer from "./personalInfo";
import platformSettingsReducer from "./platformSettings";
import participantInfoReducer from "./participantProfile";
import userSettingsReducer from "./settings";
import participantsListReducer from "./participantsList";
import userProgramsReducer from "./userPrograms";
import coachesListReducer from "./coachesList";
import programParticipantsListReducer from "./programParticipantsList";
import chatReducer from "./chat";
import createSessionReducer from "./createSession";
import uploadCsvProgrammesReducer from "./uploadCsvProgrammes";
import sessionInvitationReducer from "./sessionInvitation";
import calendarListReducer from "./calendarList";
import calendarFiltersReducer from "./calendarFilters";
import calendarGoogleEventsReducer from "./calendarGoogleEvents";
import calendarGoogleListReducer from "./calendarGoogleList";
import calendarGoogleMembersReducer from "./calendarGoogleMembers";
import reportsReducer from "./reports";
import notificationReducer from "./notification";
import matchedCoachesReducer from "./matchedCoaches";
import questionnaireLanguageReducer from "./questionnaireLanguage";
import timezoneNotificationReducer from "./timezoneNotification";
import suggestedCoachesNotificationReducer from "./suggestedCoachesNotification";
import missingFeedbackFormNotificationReducer from "./missingFeedbackFormNotification";
import participantDashboardReducer from "./participantDashboard";
import { LOGOUT } from "../constants";
import { routerReducer } from "../history";

const appReducer = combineReducers({
	auth: authReducer,
	currentPageTitle: currentPageTitleReducer,
	dashboard: dashboardReducer,
	participantDashboard: participantDashboardReducer,
	setPassword: setPasswordReducer,
	programs: registerForProgramReducer,
	currentUserInfo: personalInfoReducer,
	platformSettings: platformSettingsReducer,
	participant: participantInfoReducer,
	participantsList: participantsListReducer,
	userSettings: userSettingsReducer,
	modal: modalReducer,
	userPrograms: userProgramsReducer,
	coachesList: coachesListReducer,
	programParticipantsList: programParticipantsListReducer,
	chat: chatReducer,
	createSession: createSessionReducer,
	uploadCsvProgrammes: uploadCsvProgrammesReducer,
	sessionInvitation: sessionInvitationReducer,
	calendarList: calendarListReducer,
	calendarFilters: calendarFiltersReducer,
	calendarGoogleEvents: calendarGoogleEventsReducer,
	calendarGoogleList: calendarGoogleListReducer,
	calendarGoogleMembers: calendarGoogleMembersReducer,
	reports: reportsReducer,
	notification: notificationReducer,
	matchedCoaches: matchedCoachesReducer,
	questionnaireLanguage: questionnaireLanguageReducer,
	timezoneNotification: timezoneNotificationReducer,
	suggestedCoachesNotification: suggestedCoachesNotificationReducer,
	missingFeedbackFormNotification: missingFeedbackFormNotificationReducer,
	router: routerReducer,
});

export const rootReducer = (state, action) => {
	if (action.type === LOGOUT) {
		state = undefined;
	}

	return appReducer(state, action);
};

import React, { useEffect } from "react";
import "./DefaultAuth.scss";
import { Routes, Route } from "react-router-dom";
import pageTitles from "constants/pageTitles";
import { updatePageTitle } from "../../../../redux/actions";
import { useDispatch } from "react-redux";
import routes from "constants/routes";
import SignIn from "components/auth/signIn/SignIn";
import CreatePassword from "components/auth/createPassword/CreatePassword";
import ResetPassword from "components/auth/resetPassword/resetPassword";
import Header from "components/pages/Auth/Header/Header";
import Footer from "components/pages/Auth/Footer/Footer";
import NotFound from "../../NotFound/NotFound";

const DefaultAuth = () => {
	const { RESET_PASSWORD, CREATE_PASSWORD, SIGN_IN, REGISTER_PROGRAMS } =
		routes;
	const { LOGIN_PAGE_TITLE } = pageTitles;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updatePageTitle(LOGIN_PAGE_TITLE));
	}, []);

	return (
		<>
			<Header />
			<section className="default-auth">
				{/* fot ILTB */}
				{/* <Decor1 className="decor decor--1"/> */}
				{/* <Decor2 className="decor decor--2"/> */}
				{/* <Decor3 className="decor decor--3"/> */}
				<div className="default-auth__content">
					<Routes>
						<Route path={SIGN_IN} element={<SignIn />} />
						<Route path={RESET_PASSWORD} element={<ResetPassword />} />
						<Route path={CREATE_PASSWORD} element={<CreatePassword />} />
						<Route path={REGISTER_PROGRAMS} element={<SignIn />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			</section>
			<Footer />
		</>
	);
};

export default DefaultAuth;

import React from "react";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import "./Programmes.scss";
import ParticipantsProgrammeCard from "components/pages/Programmes/programmeCard/participantsProgrammeCard";
import SingleProgramme from "components/pages/Programmes/SingleProgramme/SingleProgramme";
import EmptyPage from "../EmptyPage/EmptyPage";
import { programmPage } from "constants/emptyPages";

export default function ParticipantProgram() {
	const userProgramsList = useSelector((state) => state.userPrograms.programs);

	return (
		<Routes>
			<Route
				index
				element={
					userProgramsList.length > 0 ? (
						<div className="section-wrap">
							{userProgramsList.map((item) => (
								<ParticipantsProgrammeCard key={item.id} {...item} />
							))}
						</div>
					) : (
						<EmptyPage emptyPageValue={programmPage()} />
					)
				}
			/>
			<Route path="single-program/:programID" element={<SingleProgramme />} />
		</Routes>
	);
}

import React from "react";
import { Routes, Route } from "react-router-dom";
import Register from "components/pages/Auth/Register/Register";
import DefaultAuth from "components/pages/Auth/DefaultAuth/DefaultAuth";
import routes from "constants/routes";
import { useSelector } from "react-redux";
import LoginEnter from "components/pages/Auth/LoginEnter";
import RegisterEnter from "components/pages/Auth/RegisterEnter";

const Auth = () => {
	const { REGISTER_FOR_PROGRAM } = routes;
	const { authToken } = useSelector((state) => state.auth);

	return (
		<Routes>
			<Route
				path={REGISTER_FOR_PROGRAM}
				element={authToken ? <RegisterEnter /> : <Register />}
			/>
			<Route path="/*" element={authToken ? <LoginEnter /> : <DefaultAuth />} />
		</Routes>
	);
};

export default Auth;

import React, { useEffect, useRef } from "react";
import { Navigate, useLocation, useMatch } from "react-router-dom";
import routes from "constants/routes";
import isSurveyFilledById from "helpers/isSurveyFilledById";
import { useDispatch, useSelector } from "react-redux";
import {
	getInfoAboutCurrentUser,
	registerForProgram,
} from "../../../redux/actions";
import InitLoader from "components/auth/initLoader/initLoader";

const RegisterEnter = () => {
	const location = useLocation();
	const match = useMatch(location.pathname);

	const searchParams = new URLSearchParams(location.search);

	// used to complete program if user decides to change program using decision box functionality
	const complete_participant_program_id = searchParams.get(
		"complete_participant_program_id"
	);

	const dispatch = useDispatch();
	const userInfoRequestId = useRef(null);
	const { authToken, userRole } = useSelector((state) => state.auth);
	const {
		requestId,
		role,
		survey = {},
	} = useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
	const {
		registeredProgramData,
		registerForProgramInProgress,
		registerForProgramError,
	} = useSelector((state) => state.programs);
	let lastRegisteredProgramId = String(
		registeredProgramData?.school_program_id
	);
	const isSurveyDataExist = survey;
	const isUserRoleExist = role || userRole;
	const shouldGetUserInfo = authToken && isUserRoleExist !== "school_admin";
	const allowRender =
		!shouldGetUserInfo ||
		(requestId === userInfoRequestId.current && !registerForProgramInProgress);

	useEffect(() => {
		if (shouldGetUserInfo) {
			userInfoRequestId.current = Date.now().toString();
			dispatch(getInfoAboutCurrentUser(userInfoRequestId.current));
		}
	}, [shouldGetUserInfo, registeredProgramData, userInfoRequestId.current]);

	const renderComponent = () => {
		const { DASHBOARD, QUESTIONNAIRE, PROGRAMME_STARTING } = routes;
		const { id, code } = match.params;

		if (
			id &&
			code &&
			id !== lastRegisteredProgramId &&
			!registerForProgramInProgress &&
			!registerForProgramError
		) {
			dispatch(
				registerForProgram({ id, code, complete_participant_program_id })
			);

			userInfoRequestId.current = Date.now().toString();
			return <InitLoader />;
		}

		if (
			isSurveyDataExist?.skip ||
			!isSurveyDataExist?.required ||
			registerForProgramError
		) {
			return <Navigate to={DASHBOARD} />;
		}

		// used to check if the participant had filled survey of the same type as on a new program
		if (
			isSurveyDataExist?.all_filled?.length > 0 &&
			isSurveyFilledById(
				isSurveyDataExist?.survey_id,
				isSurveyDataExist?.all_filled
			)
		) {
			return <Navigate to={PROGRAMME_STARTING} />;
		}

		return <Navigate to={QUESTIONNAIRE} />;
	};

	return allowRender ? renderComponent() : <InitLoader />;
};

export default RegisterEnter;

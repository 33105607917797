import React from "react";
import TextareaAutosize from "react-textarea-autosize";

const Goals = ({ errors, userInfo, setFieldValue, touched }) => {
	return (
		<div className={`section-info-block goal`}>
			<h3 className={`section-title`}>Goal</h3>
			<div
				className={`profile-row goal-text ${
					errors.goal && touched.goal ? "input-error" : ""
				}`}
			>
				<TextareaAutosize
					name="goal"
					className={`profile-row__textarea ${
						errors && errors.goal ? "input-error" : ""
					}`}
					onChange={(event) => setFieldValue("goal", event.currentTarget.value)}
					defaultValue={userInfo.goal}
				/>
				{errors && errors.goal && (
					<div className="profile-row__error">{errors?.goal}</div>
				)}
			</div>
		</div>
	);
};

export default Goals;

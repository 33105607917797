import React, { useState, useEffect, useRef } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import "./Rate.scss";
import Graph from "components/pages/Dashboard/SchoolAdmin/Charts/Attendance/Graph/Graph";
import uniqid from "uniqid";
import { ReactComponent as EmptyAttendance } from "assets/vector/dashboard/empty-attendance.svg";

const mockSessionData = {
	completed: "",
	completed_percent: "",
	not_attended: "",
	not_attended_percent: "",
	planned: "",
	planned_percent: "",
	sessions_total: "",
	title: "Session 1",
	used: "",
	isPlaceholder: true,
};

const Rate = ({ sessions = [] }) => {
	const ref = useRef();
	const [width, setWidth] = useState(0);

	const [graphs, setGraphs] = useState(null);

	function calculatePlaceholdersQuantity(width) {
		let count = 10;

		if (width < 380) {
			count = 2;
		}
		if (width >= 380 && width < 450) {
			count = 3;
		}
		if (width >= 450 && width < 590) {
			count = 4;
		}
		if (width >= 590 && width < 680) {
			count = 5;
		}
		if (width >= 680 && width < 760) {
			count = 6;
		}
		if (width >= 760 && width < 840) {
			count = 7;
		}
		if (width >= 840 && width < 920) {
			count = 8;
		}
		if (width >= 920 && width < 1000) {
			count = 9;
		}

		return count;
	}

	useEffect(() => {
		setWidth(ref.current.offsetWidth);
		// to handle page resize
		const getwidth = () => {
			setWidth(ref.current.offsetWidth);
		};
		window.addEventListener("resize", getwidth);
		// remove the event listener before the component gets unmounted
		return () => window.removeEventListener("resize", getwidth);
	}, []);

	useEffect(() => {
		const count = calculatePlaceholdersQuantity(width);
		const minCountOfGraphs = count - sessions.length;
		const newSessionsArray = [...sessions];
		if (minCountOfGraphs > 0) {
			for (let i = 0; i < minCountOfGraphs; i++) {
				newSessionsArray.push(mockSessionData);
			}
		}
		setGraphs(newSessionsArray);
	}, [sessions, width]);

	return (
		<div ref={ref}>
			{graphs?.length && sessions.length > 0 ? (
				<Scrollbars
					className="users-table rate"
					autoHeight
					autoHeightMin="100%"
					autoHeightMax="100%"
				>
					<div className="rate__row rate__row--sessions">
						<div className="rate__col">Sessions</div>
						{graphs.map((session, index) => (
							<div
								className={`rate__col ${
									session.isAdditional ? "rate__col--add-col" : ""
								}`}
								key={uniqid()}
							>
								{session.isAdditional
									? "S.E."
									: session.isPlaceholder
									? ""
									: `S.${index + 1}`}
							</div>
						))}
					</div>
					<div className="rate__row">
						<div className="rate__col">Confirmed</div>
						{graphs.map((session) => (
							<div
								className={`rate__col ${
									session.isAdditional ? "rate__col--add-col" : ""
								}`}
								key={uniqid()}
							>
								{session?.planned >= 0 ? session.planned : ""}
							</div>
						))}
					</div>
					<div className="rate__row">
						<div className="rate__col ">Used</div>
						{graphs.map((session) => (
							<div
								className={`rate__col ${
									session.isAdditional ? "rate__col--add-col" : ""
								}`}
								key={uniqid()}
							>
								{session?.used >= 0 ? session.used : ""}{" "}
								<span className="accent">
									{session?.used_percent >= 0
										? `(${session.used_percent + "%"})`
										: ""}
								</span>
							</div>
						))}
					</div>
					<div className="rate__row rate__row--graph">
						<div className="rate__col"></div>
						{graphs.map((session) => (
							<div
								className={`rate__col ${
									session.isAdditional ? "rate__col--add-graph" : ""
								}`}
								key={uniqid()}
							>
								<Graph {...session} />
							</div>
						))}
					</div>
				</Scrollbars>
			) : (
				<div className="rate__empty">
					<EmptyAttendance className="icon" />
					Data is not available yet
				</div>
			)}
		</div>
	);
};

export default Rate;

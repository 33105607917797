import Button from "components/button/Button";
import React from "react";
import styles from "../DecisionBox.module.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "constants/routes";

const CompleteProgram = ({
	setDecisionsData,
	decisionBox,
	programTopicId,
	decisionsData,
}) => {
	const { id: programId } =
		useSelector((state) => state.userPrograms.singleProgram) || {};
	const { avatar, name, role } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
	const { DECISION_FORM } = routes;

	const feedbackForm = decisionBox.find(
		(box) => box.decision?.type === "complete"
	)?.form_template;

	const foundDecisionBox = decisionBox.find(
		(box) => box.decision?.type === "complete"
	);

	return (
		<>
			<h2>Complete program</h2>
			<div className={styles.actions}>
				<Button
					className={`button button--orange-invert ${styles.button} decision`}
					onClick={() =>
						setDecisionsData((prev) => ({
							...prev,
							step: "selectDecision",
						}))
					}
				>
					Go back
				</Button>
				{feedbackForm ? (
					<Link
						to={{ pathname: DECISION_FORM }}
						state={{
							participant_program_id: programId,
							participant_program_topic_id: programTopicId,
							name,
							avatar,
							role,
							form: feedbackForm,
							decisionsData: {
								id: foundDecisionBox.id,
								decision_id: foundDecisionBox.decision.id,
							},
						}}
						className={`button button--blue ${styles.button} decision`}
					>
						Fill out the form
					</Link>
				) : (
					<Button
						type="button"
						className={`button button--orange-invert ${styles.button} decision`}
						disabled
					>
						Fill out the form
					</Button>
				)}
			</div>
		</>
	);
};
export default CompleteProgram;

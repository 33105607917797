import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Auth/Footer/Footer";
import Header from "../Auth/Header/Header";
import CustomPlatformSettings from "services/platformSettings/platformSettings";
import "./Questionnaire.scss";
import StartPage from "./StartPage/StartPage";
import { Navigate } from "react-router-dom";
import { getPlatformSettings } from "../../../redux/actions";

const Questionnaire = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (window.localStorage.getItem("school_id")) {
			dispatch(getPlatformSettings(window.localStorage.getItem("school_id")));
		}
	}, []);

	const platformSettings = useSelector(
		(state) => state.platformSettings.platformSettings
	);

	const { survey, role } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};

	if (platformSettings) {
		localStorage.setItem(
			"platformSettings",
			JSON.stringify(platformSettings.settings)
		);
		CustomPlatformSettings();
	}

	if (role === "participant" && (!survey?.required || survey?.skip)) {
		return <Navigate to="/" />;
	}

	return (
		<section className="questionnaire-section">
			<Header />
			{/* for ILTB */}
			{/* <img className="decor-image-left" src={decor_image1} alt="" /> */}
			{/* <img className="decor-image-right" src={decor_image2} alt="" /> */}
			<StartPage />
			<Footer />
		</section>
	);
};

export default Questionnaire;

export const dashboardConstant = {
	GET_SESSIONS: "GET_SESSIONS",
	GET_SESSIONS_SUCCESS: "GET_SESSIONS_SUCCESS",
	GET_SESSIONS_LOAD_SUCCESS: "GET_SESSIONS_LOAD_SUCCESS",
	GET_SESSIONS_ERROR: "GET_SESSIONS_ERROR",

	GET_SESSIONS_CREDITS: "GET_SESSIONS_CREDITS",
	GET_SESSIONS_CREDITS_SUCCESS: "GET_SESSIONS_CREDITS_SUCCESS",
	GET_SESSIONS_CREDITS_ERROR: "GET_SESSIONS_CREDITS_ERROR",

	GET_PARTICIPANTS: "GET_PARTICIPANTS",
	GET_PARTICIPANTS_SUCCESS: "GET_PARTICIPANTS_SUCCESS",
	GET_PARTICIPANTS_ERROR: "GET_PARTICIPANTS_ERROR",

	GET_ATTENDANCE: "GET_ATTENDANCE",
	GET_ATTENDANCE_SUCCESS: "GET_ATTENDANCE_SUCCESS",
	GET_ATTENDANCE_ERROR: "GET_ATTENDANCE_ERROR",

	GET_AVERAGE_MARK: "GET_AVERAGE_MARK",
	GET_AVERAGE_MARK_SUCCESS: "GET_AVERAGE_MARK_SUCCESS",
	GET_AVERAGE_MARK_LOAD_SUCCESS: "GET_AVERAGE_MARK_LOAD_SUCCESS",
	GET_AVERAGE_MARK_ERROR: "GET_AVERAGE_MARK_ERROR",

	SET_CURRENT_SCHOOL_PROGRAM: "SET_CURRENT_SCHOOL_PROGRAM",
	GET_LIST_USER_SCHOOL_PROGRAMS: "GET_LIST_USER_SCHOOL_PROGRAMS",
	GET_LIST_USER_SCHOOL_PROGRAMS_ON_SCROLL:
		"GET_LIST_USER_SCHOOL_PROGRAMS_ON_SCROLL",
	GET_LIST_USER_SCHOOL_PROGRAMS_SUCCESS:
		"GET_LIST_USER_SCHOOL_PROGRAMS_SUCCESS",
	GET_LIST_USER_SCHOOL_PROGRAMS_ERROR: "GET_LIST_USER_SCHOOL_PROGRAMS_ERROR",

	GET_SCHOOL_OVERVIEW_GRAPHS: "GET_SCHOOL_OVERVIEW_GRAPHS",
	GET_SCHOOL_OVERVIEW_GRAPHS_SUCCESS: "GET_SCHOOL_OVERVIEW_GRAPHS_SUCCESS",
	GET_SCHOOL_OVERVIEW_GRAPHS_ERROR: "GET_SCHOOL_OVERVIEW_GRAPHS_ERROR",

	SHOW_DASHBOARD_PAGE: "SHOW_DASHBOARD_PAGE",
	LOAD_DASHBOARD_PAGE: "LOAD_DASHBOARD_PAGE",
	IS_LEAD_COACH_DASHBOARD_PAGE: "IS_LEAD_COACH_DASHBOARD_PAGE",

	CLEAR_DASHBOARD_DATA: "CLEAR_DASHBOARD_DATA",
};

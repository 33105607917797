import { dashboardConstant } from "../constants";

const initialState = {
	showDashboardPage: false,
	loadDashboardPage: true,
	isLeadCoachDashboardPage:
		JSON.parse(localStorage.getItem("isLeadCoachDashboardPage")) || false,

	listUserSchoolProgramsLoading: false,
	listUserSchoolPrograms: [],
	listUserSchoolProgramsTotal: null,
	listUserSchoolProgramsErrors: null,

	currentSchoolProgram: null,

	sessions: null,
	sessionsLoading: false,
	sessionsErrors: null,

	sessionsCredits: null,
	sessionsCreditsLoading: false,
	sessionsCreditsErrors: null,

	participants: null,
	participantsLoading: false,
	participantsErrors: null,

	attendance: null,
	attendanceLoading: false,
	attendanceErrors: null,

	averageMark: null,
	averageMarkLoad: true,
	averageMarkErrors: null,

	schoolOverviewLoading: false,
	schoolOverviewData: null,
	schoolOverviewError: null,
};

export default function dashboardReducer(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case dashboardConstant.GET_SESSIONS:
			return {
				...state,
				sessionsLoading: true,
			};
		case dashboardConstant.GET_SESSIONS_SUCCESS:
			return {
				...state,
				sessions: payload,
				sessionsLoading: false,
			};
		case dashboardConstant.GET_SESSIONS_ERROR:
			return {
				...state,
				sessionsErrors: payload,
				sessionsLoading: false,
			};

		case dashboardConstant.GET_SESSIONS_CREDITS:
			return {
				...state,
				sessionsCreditsLoading: true,
			};
		case dashboardConstant.GET_SESSIONS_CREDITS_SUCCESS:
			return {
				...state,
				sessionsCredits: payload,
				sessionsCreditsLoading: false,
			};
		case dashboardConstant.GET_SESSIONS_CREDITS_ERROR:
			return {
				...state,
				sessionsCreditsErrors: payload,
				sessionsCreditsLoading: false,
			};

		case dashboardConstant.GET_PARTICIPANTS:
			return {
				...state,
				participantsLoading: true,
			};
		case dashboardConstant.GET_PARTICIPANTS_SUCCESS:
			return {
				...state,
				participants: payload,
				participantsLoading: false,
			};
		case dashboardConstant.GET_PARTICIPANTS_ERROR:
			return {
				...state,
				participantsErrors: payload,
				participantsLoading: false,
			};

		case dashboardConstant.GET_ATTENDANCE:
			return {
				...state,
				attendanceLoading: true,
			};

		case dashboardConstant.GET_ATTENDANCE_SUCCESS:
			return {
				...state,
				attendance: payload,
				attendanceLoading: false,
			};

		case dashboardConstant.GET_ATTENDANCE_ERROR:
			return {
				...state,
				attendanceErrors: payload,
				attendanceLoading: false,
			};

		case dashboardConstant.GET_AVERAGE_MARK_SUCCESS:
			return {
				...state,
				averageMark: payload,
			};
		case dashboardConstant.GET_AVERAGE_MARK_LOAD_SUCCESS:
			return {
				...state,
				averageMarkLoad: payload,
			};
		case dashboardConstant.GET_AVERAGE_MARK_ERROR:
			return {
				...state,
				averageMarkErrors: payload,
			};

		case dashboardConstant.SET_CURRENT_SCHOOL_PROGRAM:
			return {
				...state,
				currentSchoolProgram: payload,
			};
		case dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS_ON_SCROLL:
			return {
				...state,
				listUserSchoolProgramsLoading: true,
			};

		case dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS_SUCCESS:
			return {
				...state,
				listUserSchoolPrograms: payload?.programs,
				listUserSchoolProgramsTotal: payload?.total,
				listUserSchoolProgramsLoading: false,
			};

		case dashboardConstant.GET_LIST_USER_SCHOOL_PROGRAMS_ERROR:
			return {
				...state,
				listUserSchoolProgramsErrors: payload,
				listUserSchoolProgramsLoading: false,
			};

		case dashboardConstant.GET_SCHOOL_OVERVIEW_GRAPHS:
			return {
				...state,
				schoolOverviewLoading: true,
			};

		case dashboardConstant.GET_SCHOOL_OVERVIEW_GRAPHS_SUCCESS:
			return {
				...state,
				schoolOverviewData: payload,
				schoolOverviewLoading: false,
			};

		case dashboardConstant.GET_SCHOOL_OVERVIEW_GRAPHS_ERROR:
			return {
				...state,
				schoolOverviewError: payload,
				schoolOverviewLoading: false,
			};

		case dashboardConstant.SHOW_DASHBOARD_PAGE:
			return {
				...state,
				showDashboardPage: payload,
			};
		case dashboardConstant.LOAD_DASHBOARD_PAGE:
			return {
				...state,
				loadDashboardPage: payload,
			};
		case dashboardConstant.IS_LEAD_COACH_DASHBOARD_PAGE:
			localStorage.setItem("isLeadCoachDashboardPage", JSON.stringify(payload));
			return {
				...state,
				isLeadCoachDashboardPage: payload,
			};

		case dashboardConstant.CLEAR_DASHBOARD_DATA:
			return {
				...state,
				showDashboardPage: false,
				loadDashboardPage: true,

				listUserSchoolProgramsLoading: false,
				listUserSchoolPrograms: [],
				listUserSchoolProgramsTotal: null,
				listUserSchoolProgramsErrors: null,

				currentSchoolProgram: null,

				sessions: null,
				sessionsLoading: false,
				sessionsErrors: null,

				sessionsCredits: null,
				sessionsCreditsLoading: false,
				sessionsCreditsErrors: null,

				participants: null,
				participantsLoading: true,
				participantsErrors: null,

				attendance: null,
				attendanceLoading: false,
				attendanceErrors: null,

				averageMark: null,
				averageMarkLoad: true,
				averageMarkErrors: null,

				schoolOverviewData: null,
				schoolOverviewLoading: false,
				schoolOverviewError: null,
			};

		default:
			return state;
	}
}

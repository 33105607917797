import React from "react";
import { useMediaQuery } from "react-responsive";
import { Scrollbars } from "react-custom-scrollbars-2";

const PerfectMediaScroll = ({ children, mediaQuery = {}, ...props }) => {
	const isMediaQuery = useMediaQuery(mediaQuery);

	return isMediaQuery ? (
		<Scrollbars {...props}>{children}</Scrollbars>
	) : (
		<div {...props}>{children}</div>
	);
};

export default PerfectMediaScroll;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import avatar from "assets/vector/default-avatar.svg";

import "./AddParticipantsToChat.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ReactComponent as CloseModalSvg } from "assets/vector/chat/close-search.svg";
import uniqid from "uniqid";
import SearchChatUsers from "./SearchChatUsers/SearchChatUsers";

export default function AddParticipantsToChat({
	handlerParticipantsData,
	closeModalParticipants,
}) {
	const [isSearchOpen, setIsSearchOpen] = useState(false);
	const [chatUsersList, setChatUsersList] = useState(null);

	const specifiedUser = true;
	const getListUsersChat = useSelector((state) => state.chat.listUsers);
	const getStatusUsers = useSelector((state) => state.chat.emptyUsers);
	// const getErrorsUsers = useSelector(state => state.chat.errorUsers);

	useEffect(() => {
		setChatUsersList(getListUsersChat);
	}, []);

	return (
		<section className="add-participants-to-chat">
			{!getStatusUsers ? (
				<div className="modal-participant-list">
					<div className="top-block">
						{!isSearchOpen && <h2 className="title">Add Participants</h2>}
						<SearchChatUsers
							users={getListUsersChat}
							setChatUsersList={setChatUsersList}
							setIsSearchOpen={setIsSearchOpen}
							isSearchOpen={isSearchOpen}
						/>
						<div className="choose">Choose</div>
						<div className="close-modal">
							<CloseModalSvg
								onClick={(e) => {
									e.stopPropagation();
									closeModalParticipants();
								}}
							/>
						</div>
					</div>

					<Scrollbars
						className="participants-list-wrapper"
						autoHeight
						autoHeightMin="100%"
						autoHeightMax="100%"
					>
						{chatUsersList && chatUsersList.length > 0 ? (
							chatUsersList?.map((user) => {
								return (
									<div
										className="item"
										key={uniqid()}
										onClick={(e) => {
											e.stopPropagation();
											handlerParticipantsData(user, specifiedUser);
										}}
									>
										<div className="participant-info">
											<img
												src={user.avatar ? user.avatar : avatar}
												className={`avatar`}
												alt=""
											/>
											<div className="user-name">{user.full_name}</div>
										</div>

										<div className="icon add-participants-modal">
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path d="M22.9052 0.0824015L0.415905 11.409C-0.123787 11.6808 -0.138068 12.4498 0.394013 12.7401L7.82211 16.793L14.1105 9.65672C14.3844 9.34478 14.8593 9.31396 15.1713 9.58785C15.4832 9.86174 15.514 10.3367 15.2402 10.6486L9.17524 17.5313L16.9415 21.7686C17.3623 21.9983 17.8886 21.78 18.023 21.3194L23.9649 0.964334C24.1486 0.3351 23.4911 -0.212719 22.9052 0.0824015Z" />

												<path d="M8.27051 18.7498V23.2486C8.27051 23.9998 9.25655 24.2845 9.65751 23.6503L11.6053 20.5693L8.27051 18.7498Z" />
											</svg>
										</div>
									</div>
								);
							})
						) : (
							<p className="no-searched-users">
								There are no results matching your search request
							</p>
						)}
					</Scrollbars>
				</div>
			) : (
				<div className={`modal-participant-list`}>
					<div className="top-block">
						<h2 className={`title`}>No user</h2>

						<div className="close-modal">
							<CloseModalSvg
								onClick={(e) => {
									e.stopPropagation();
									closeModalParticipants();
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</section>
	);
}

import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import logo from "assets/vector/pdf-file.svg";
import downloadImage from "assets/vector/download-invoice.svg";
import ReportsSortingButtons from "../../ReportsSortingButtons/ReportsSortingButtons";
import moment from "moment";
import "./InvoicesTable.scss";

const InvoicesTable = ({ invoices, setOffsetReports }) => {
	const downloadInvoice = (url) => {
		window.open(url);
	};
	return (
		<div className="invoices-table__wrapper">
			<Scrollbars
				className="invoices-table"
				autoHeight
				autoHeightMin="100%"
				autoHeightMax="100%"
			>
				<div className="invoices-table__row head">
					<div className="invoices-table__col logo" />
					<div className="invoices-table__col">Code</div>
					<div className="invoices-table__col">Status</div>
					<div className="invoices-table__col">
						<ReportsSortingButtons
							setOffsetReports={setOffsetReports}
							sortingValue="created_at"
						/>
						Date
					</div>
					<div className="invoices-table__col">Sum</div>
					<div className="invoices-table__col">Currency</div>
					<div className="invoices-table__col download" />
				</div>
				{invoices.length > 0 ? (
					invoices.map((invoice) => (
						<div className="invoices-table__row" key={invoice.id}>
							<div className="invoices-table__col logo">
								<img src={logo} alt="logo" />
							</div>
							<div className="invoices-table__col">{invoice.code}</div>
							<div className="invoices-table__col">{invoice.status}</div>
							<div className="invoices-table__col">
								{moment(invoice.updated_at).format("MMMM D, Y")}{" "}
							</div>
							<div className="invoices-table__col">{invoice.total}</div>
							<div className="invoices-table__col">{invoice.currency}</div>
							<div className="invoices-table__col download">
								<span onClick={() => downloadInvoice(invoice.link)}>
									<img src={downloadImage} alt="download-icon" />
									Download
								</span>
							</div>
						</div>
					))
				) : (
					<div className="empty-data">
						There are no reports of selected type
					</div>
				)}
			</Scrollbars>
		</div>
	);
};

export default InvoicesTable;

import React, { useState, forwardRef } from "react";
import "./SiteNotification.scss";
import { useSelector } from "react-redux";

import NotificationHeader from "./NotificationHeader";
import EmptyNotification from "./EmptyNotification";
import NotificationItems from "./NotificationItems";
import PopupClearAll from "./PopupClearAll";

const SiteNotification = forwardRef((props, ref) => {
	const loadNotifications = useSelector(
		(state) => state.notification.loadNotifications
	);
	const userNotificationEmpty = useSelector(
		(state) => state.notification.userNotificationEmpty
	);
	const userNotification = useSelector(
		(state) => state.notification.userNotification
	);

	const [openPopupClearAll, setOpenPopupClearAll] = useState(false);

	return (
		<div className="site-notification-overlay">
			<div className="site-notification-wrapper" ref={ref}>
				{openPopupClearAll && (
					<PopupClearAll setOpenPopupClearAll={setOpenPopupClearAll} />
				)}
				<NotificationHeader
					userNotificationEmpty={userNotificationEmpty}
					setOpenPopupClearAll={setOpenPopupClearAll}
				/>
				{loadNotifications && (
					<>
						{userNotificationEmpty ? (
							<EmptyNotification />
						) : (
							<NotificationItems userNotification={userNotification} />
						)}
					</>
				)}
			</div>
		</div>
	);
});

export default SiteNotification;

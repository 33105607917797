import routes from "./routes";

const {
	DASHBOARD,
	PARTICIPANTS,
	COACHES,
	PROGRAMMES,
	CALENDAR,
	EVALUATIONS,
	REPORTS_PAGE,
} = routes;

export const schoolAdminHeaderMenu = [
	{
		text: "Dashboard",
		route: DASHBOARD,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="3" y="3" width="10" height="10" rx="2" />
				<rect x="3" y="15" width="10" height="10" rx="2" />
				<rect x="15" y="3" width="10" height="10" rx="2" />
				<rect x="15" y="15" width="10" height="10" rx="2" />
			</svg>
		),
	},
	{
		text: "Participants",
		route: PARTICIPANTS,
		icon: (
			<svg
				className="menu-icon"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M9.85722 9.63406C11.1808 9.63406 12.3268 9.15936 13.2633 8.22278C14.1997 7.28635 14.6744 6.14057 14.6744 4.81688C14.6744 3.49364 14.1997 2.34771 13.2631 1.41097C12.3265 0.474699 11.1806 0 9.85722 0C8.53352 0 7.38775 0.474699 6.45132 1.41113C5.51489 2.34756 5.04004 3.49349 5.04004 4.81688C5.04004 6.14057 5.51489 7.2865 6.45147 8.22293C7.3805 9.1592 8.53398 9.63406 9.85722 9.63406Z"></path>
				<path d="M18.2862 15.3793C18.2592 14.9896 18.2046 14.5645 18.1242 14.1156C18.043 13.6633 17.9385 13.2358 17.8134 12.845C17.6841 12.4411 17.5083 12.0423 17.291 11.66C17.0655 11.2633 16.8006 10.9178 16.5034 10.6336C16.1926 10.3362 15.812 10.0971 15.372 9.92267C14.9334 9.74918 14.4474 9.66129 13.9276 9.66129C13.7234 9.66129 13.526 9.74506 13.1446 9.99332C12.91 10.1464 12.6355 10.3234 12.3291 10.5191C12.0671 10.6861 11.7121 10.8425 11.2738 10.9841C10.8461 11.1225 10.4118 11.1927 9.98318 11.1927C9.55456 11.1927 9.12045 11.1225 8.69229 10.9841C8.25437 10.8426 7.89945 10.6862 7.63776 10.5193C7.33427 10.3253 7.05961 10.1483 6.82142 9.99316C6.44056 9.7449 6.24296 9.66113 6.0388 9.66113C5.51878 9.66113 5.03295 9.74918 4.59456 9.92282C4.15481 10.0969 3.7741 10.336 3.46297 10.6337C3.16589 10.9181 2.90084 11.2635 2.67562 11.66C2.45849 12.0423 2.28271 12.441 2.15332 12.8452C2.02835 13.2359 1.92383 13.6633 1.84265 14.1156C1.76224 14.5639 1.70761 14.9892 1.6806 15.3798C1.65405 15.7625 1.64062 16.1597 1.64062 16.5608C1.64062 17.6048 1.9725 18.45 2.62695 19.0733C3.27331 19.6884 4.12856 20.0004 5.1686 20.0004H14.7987C15.8387 20.0004 16.6937 19.6886 17.3402 19.0733C17.9948 18.4505 18.3267 17.6051 18.3267 16.5607C18.3265 16.1577 18.3129 15.7602 18.2862 15.3793Z"></path>
			</svg>
		),
	},
	{
		text: "Coaches",
		route: COACHES,
		icon: (
			<svg
				className={`menu-icon`}
				width="24"
				height="24"
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M16.7987 9.66848V22.8257C16.7987 23.4733 16.2719 24 15.6244 24H8.37617C7.72859 24 7.20187 23.4733 7.20187 22.8257V9.66848C7.20187 9.0209 7.72859 8.49406 8.37617 8.49406H15.6244C16.2719 8.49418 16.7987 9.02102 16.7987 9.66848ZM21.6844 5.4113C21.3806 5.03699 20.9299 4.82227 20.4479 4.82227H17.7424C17.6919 4.19323 17.4495 3.18719 16.594 1.92689C16.7081 1.73598 16.775 1.51363 16.775 1.27544C16.7748 0.572247 16.2026 0 15.4993 0C14.7959 0 14.2236 0.572247 14.2236 1.27556C14.2236 1.97887 14.7959 2.55112 15.4993 2.55112C15.5319 2.55112 15.5639 2.54865 15.596 2.54619C16.2935 3.56349 16.5029 4.35539 16.5589 4.82227H7.44159C7.49768 4.35551 7.70712 3.56408 8.40351 2.54783C8.42498 2.54889 8.4461 2.55112 8.46781 2.55112C9.17112 2.55112 9.74337 1.97887 9.74337 1.27556C9.74337 0.572247 9.17112 0 8.46781 0C7.76438 0 7.19213 0.572247 7.19213 1.27556C7.19213 1.52455 7.26488 1.75628 7.38879 1.95282C6.54749 3.20116 6.30836 4.19757 6.25826 4.82239H3.55273C3.07071 4.82239 2.62002 5.03699 2.31624 5.41141C2.01258 5.7856 1.89524 6.27078 1.99439 6.74235L2.91595 11.1277C3.07001 11.8607 3.72533 12.3925 4.47428 12.3925H6.02863V9.66848C6.02863 8.37391 7.08184 7.3207 8.37629 7.3207H15.6245C16.9189 7.3207 17.9721 8.37391 17.9721 9.66848V12.3924H19.5265C20.2754 12.3924 20.9308 11.8605 21.0848 11.1275L22.0063 6.74235C22.1054 6.27066 21.9881 5.7856 21.6844 5.4113Z" />
			</svg>
		),
	},
	{
		text: "Programmes",
		route: PROGRAMMES,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="4" y="6" width="3" height="3" rx="1" />
				<rect x="9" y="6" width="15" height="3" rx="1" />
				<rect x="4" y="12" width="3" height="3" rx="1" />
				<rect x="9" y="12" width="15" height="3" rx="1" />
				<rect x="4" y="18" width="3" height="3" rx="1" />
				<rect x="9" y="18" width="15" height="3" rx="1" />
			</svg>
		),
	},
	{
		text: "Calendar",
		route: CALENDAR,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="3" y="13" width="22" height="13" rx="2" />
				<path d="M8 3.5C8 2.67157 8.67157 2 9.5 2C10.3284 2 11 2.67157 11 3.5V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5V3.5Z" />
				<path d="M17 3.5C17 2.67157 17.6716 2 18.5 2C19.3284 2 20 2.67157 20 3.5V5.5C20 6.32843 19.3284 7 18.5 7C17.6716 7 17 6.32843 17 5.5V3.5Z" />
				<path d="M15 5H13V5.5C13 7.433 11.433 9 9.5 9C7.567 9 6 7.433 6 5.5V5H5C3.89543 5 3 5.89543 3 7V9C3 10.1046 3.89543 11 5 11H23C24.1046 11 25 10.1046 25 9V7C25 5.89543 24.1046 5 23 5H22V5.5C22 7.433 20.433 9 18.5 9C16.567 9 15 7.433 15 5.5V5ZM17 5V5.5C17 6.32843 17.6716 7 18.5 7C19.3284 7 20 6.32843 20 5.5V5H17ZM8 5.5V5H11V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5Z" />
			</svg>
		),
	},
	{
		text: "Reports",
		route: REPORTS_PAGE,
		icon: (
			<svg
				className={`menu-icon`}
				width="24"
				height="23"
				viewBox="0 0 24 23"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M6.22993 22.705C4.85652 23.4296 3.95577 22.7728 4.21782 21.2385L5.31988 14.7916L0.651805 10.2262C-0.459314 9.13936 -0.115227 8.07712 1.42006 7.85328L7.87151 6.91242L10.7567 1.04713C11.4434 -0.349043 12.5567 -0.349043 13.2434 1.04713L16.1284 6.91242L22.5798 7.85328C24.1153 8.07712 24.4592 9.13936 23.3483 10.2262L18.6802 14.7919L19.7825 21.2388C20.0446 22.773 19.1438 23.4296 17.7704 22.7052L12.0001 19.6615L6.22993 22.705Z" />
			</svg>
		),
	},
];

export const schoolAdminHeaderMenuAccessDenied = [
	{
		text: "Dashboard",
		route: DASHBOARD,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="3" y="3" width="10" height="10" rx="2" />
				<rect x="3" y="15" width="10" height="10" rx="2" />
				<rect x="15" y="3" width="10" height="10" rx="2" />
				<rect x="15" y="15" width="10" height="10" rx="2" />
			</svg>
		),
	},
	{
		text: "Participants",
		route: PARTICIPANTS,
		icon: (
			<svg
				className="menu-icon"
				width="20"
				height="20"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M9.85722 9.63406C11.1808 9.63406 12.3268 9.15936 13.2633 8.22278C14.1997 7.28635 14.6744 6.14057 14.6744 4.81688C14.6744 3.49364 14.1997 2.34771 13.2631 1.41097C12.3265 0.474699 11.1806 0 9.85722 0C8.53352 0 7.38775 0.474699 6.45132 1.41113C5.51489 2.34756 5.04004 3.49349 5.04004 4.81688C5.04004 6.14057 5.51489 7.2865 6.45147 8.22293C7.3805 9.1592 8.53398 9.63406 9.85722 9.63406Z"></path>
				<path d="M18.2862 15.3793C18.2592 14.9896 18.2046 14.5645 18.1242 14.1156C18.043 13.6633 17.9385 13.2358 17.8134 12.845C17.6841 12.4411 17.5083 12.0423 17.291 11.66C17.0655 11.2633 16.8006 10.9178 16.5034 10.6336C16.1926 10.3362 15.812 10.0971 15.372 9.92267C14.9334 9.74918 14.4474 9.66129 13.9276 9.66129C13.7234 9.66129 13.526 9.74506 13.1446 9.99332C12.91 10.1464 12.6355 10.3234 12.3291 10.5191C12.0671 10.6861 11.7121 10.8425 11.2738 10.9841C10.8461 11.1225 10.4118 11.1927 9.98318 11.1927C9.55456 11.1927 9.12045 11.1225 8.69229 10.9841C8.25437 10.8426 7.89945 10.6862 7.63776 10.5193C7.33427 10.3253 7.05961 10.1483 6.82142 9.99316C6.44056 9.7449 6.24296 9.66113 6.0388 9.66113C5.51878 9.66113 5.03295 9.74918 4.59456 9.92282C4.15481 10.0969 3.7741 10.336 3.46297 10.6337C3.16589 10.9181 2.90084 11.2635 2.67562 11.66C2.45849 12.0423 2.28271 12.441 2.15332 12.8452C2.02835 13.2359 1.92383 13.6633 1.84265 14.1156C1.76224 14.5639 1.70761 14.9892 1.6806 15.3798C1.65405 15.7625 1.64062 16.1597 1.64062 16.5608C1.64062 17.6048 1.9725 18.45 2.62695 19.0733C3.27331 19.6884 4.12856 20.0004 5.1686 20.0004H14.7987C15.8387 20.0004 16.6937 19.6886 17.3402 19.0733C17.9948 18.4505 18.3267 17.6051 18.3267 16.5607C18.3265 16.1577 18.3129 15.7602 18.2862 15.3793Z"></path>
			</svg>
		),
	},
	{
		text: "Programmes",
		route: PROGRAMMES,
		exact: false,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="4" y="6" width="3" height="3" rx="1" />
				<rect x="9" y="6" width="15" height="3" rx="1" />
				<rect x="4" y="12" width="3" height="3" rx="1" />
				<rect x="9" y="12" width="15" height="3" rx="1" />
				<rect x="4" y="18" width="3" height="3" rx="1" />
				<rect x="9" y="18" width="15" height="3" rx="1" />
			</svg>
		),
	},
	{
		text: "Calendar",
		route: CALENDAR,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="3" y="13" width="22" height="13" rx="2" />
				<path d="M8 3.5C8 2.67157 8.67157 2 9.5 2C10.3284 2 11 2.67157 11 3.5V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5V3.5Z" />
				<path d="M17 3.5C17 2.67157 17.6716 2 18.5 2C19.3284 2 20 2.67157 20 3.5V5.5C20 6.32843 19.3284 7 18.5 7C17.6716 7 17 6.32843 17 5.5V3.5Z" />
				<path d="M15 5H13V5.5C13 7.433 11.433 9 9.5 9C7.567 9 6 7.433 6 5.5V5H5C3.89543 5 3 5.89543 3 7V9C3 10.1046 3.89543 11 5 11H23C24.1046 11 25 10.1046 25 9V7C25 5.89543 24.1046 5 23 5H22V5.5C22 7.433 20.433 9 18.5 9C16.567 9 15 7.433 15 5.5V5ZM17 5V5.5C17 6.32843 17.6716 7 18.5 7C19.3284 7 20 6.32843 20 5.5V5H17ZM8 5.5V5H11V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5Z" />
			</svg>
		),
	},
	{
		text: "Reports",
		route: REPORTS_PAGE,
		icon: (
			<svg
				className={`menu-icon`}
				width="24"
				height="23"
				viewBox="0 0 24 23"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M6.22993 22.705C4.85652 23.4296 3.95577 22.7728 4.21782 21.2385L5.31988 14.7916L0.651805 10.2262C-0.459314 9.13936 -0.115227 8.07712 1.42006 7.85328L7.87151 6.91242L10.7567 1.04713C11.4434 -0.349043 12.5567 -0.349043 13.2434 1.04713L16.1284 6.91242L22.5798 7.85328C24.1153 8.07712 24.4592 9.13936 23.3483 10.2262L18.6802 14.7919L19.7825 21.2388C20.0446 22.773 19.1438 23.4296 17.7704 22.7052L12.0001 19.6615L6.22993 22.705Z" />
			</svg>
		),
	},
];

export const participantHeaderMenu = [
	{
		text: "Dashboard",
		route: DASHBOARD,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="3" y="3" width="10" height="10" rx="2" />
				<rect x="3" y="15" width="10" height="10" rx="2" />
				<rect x="15" y="3" width="10" height="10" rx="2" />
				<rect x="15" y="15" width="10" height="10" rx="2" />
			</svg>
		),
	},
	{
		text: "Programmes",
		route: PROGRAMMES,
		exact: false,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="4" y="6" width="3" height="3" rx="1" />
				<rect x="9" y="6" width="15" height="3" rx="1" />
				<rect x="4" y="12" width="3" height="3" rx="1" />
				<rect x="9" y="12" width="15" height="3" rx="1" />
				<rect x="4" y="18" width="3" height="3" rx="1" />
				<rect x="9" y="18" width="15" height="3" rx="1" />
			</svg>
		),
	},
	{
		text: "Calendar",
		route: CALENDAR,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="3" y="13" width="22" height="13" rx="2" />
				<path d="M8 3.5C8 2.67157 8.67157 2 9.5 2C10.3284 2 11 2.67157 11 3.5V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5V3.5Z" />
				<path d="M17 3.5C17 2.67157 17.6716 2 18.5 2C19.3284 2 20 2.67157 20 3.5V5.5C20 6.32843 19.3284 7 18.5 7C17.6716 7 17 6.32843 17 5.5V3.5Z" />
				<path d="M15 5H13V5.5C13 7.433 11.433 9 9.5 9C7.567 9 6 7.433 6 5.5V5H5C3.89543 5 3 5.89543 3 7V9C3 10.1046 3.89543 11 5 11H23C24.1046 11 25 10.1046 25 9V7C25 5.89543 24.1046 5 23 5H22V5.5C22 7.433 20.433 9 18.5 9C16.567 9 15 7.433 15 5.5V5ZM17 5V5.5C17 6.32843 17.6716 7 18.5 7C19.3284 7 20 6.32843 20 5.5V5H17ZM8 5.5V5H11V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5Z" />
			</svg>
		),
	},
	{
		text: "Reports",
		route: REPORTS_PAGE,
		icon: (
			<svg
				className={`menu-icon`}
				width="24"
				height="23"
				viewBox="0 0 24 23"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M6.22993 22.705C4.85652 23.4296 3.95577 22.7728 4.21782 21.2385L5.31988 14.7916L0.651805 10.2262C-0.459314 9.13936 -0.115227 8.07712 1.42006 7.85328L7.87151 6.91242L10.7567 1.04713C11.4434 -0.349043 12.5567 -0.349043 13.2434 1.04713L16.1284 6.91242L22.5798 7.85328C24.1153 8.07712 24.4592 9.13936 23.3483 10.2262L18.6802 14.7919L19.7825 21.2388C20.0446 22.773 19.1438 23.4296 17.7704 22.7052L12.0001 19.6615L6.22993 22.705Z" />
			</svg>
		),
	},
];

export const coachHeaderMenu = (isLeadCoachDashboardPage) => [
	{
		text: "Dashboard",
		route: DASHBOARD,
		icon: (
			<svg
				className={`menu-icon`}
				width="28"
				height="28"
				viewBox="0 0 28 28"
				xmlns="http://www.w3.org/2000/svg"
			>
				<rect x="3" y="3" width="10" height="10" rx="2" />
				<rect x="3" y="15" width="10" height="10" rx="2" />
				<rect x="15" y="3" width="10" height="10" rx="2" />
				<rect x="15" y="15" width="10" height="10" rx="2" />
			</svg>
		),
	},
	...(isLeadCoachDashboardPage
		? []
		: [
				{
					text: "Participants",
					route: PARTICIPANTS,
					icon: (
						<svg
							className="menu-icon"
							width="20"
							height="20"
							viewBox="0 0 20 20"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M9.85722 9.63406C11.1808 9.63406 12.3268 9.15936 13.2633 8.22278C14.1997 7.28635 14.6744 6.14057 14.6744 4.81688C14.6744 3.49364 14.1997 2.34771 13.2631 1.41097C12.3265 0.474699 11.1806 0 9.85722 0C8.53352 0 7.38775 0.474699 6.45132 1.41113C5.51489 2.34756 5.04004 3.49349 5.04004 4.81688C5.04004 6.14057 5.51489 7.2865 6.45147 8.22293C7.3805 9.1592 8.53398 9.63406 9.85722 9.63406Z"></path>
							<path d="M18.2862 15.3793C18.2592 14.9896 18.2046 14.5645 18.1242 14.1156C18.043 13.6633 17.9385 13.2358 17.8134 12.845C17.6841 12.4411 17.5083 12.0423 17.291 11.66C17.0655 11.2633 16.8006 10.9178 16.5034 10.6336C16.1926 10.3362 15.812 10.0971 15.372 9.92267C14.9334 9.74918 14.4474 9.66129 13.9276 9.66129C13.7234 9.66129 13.526 9.74506 13.1446 9.99332C12.91 10.1464 12.6355 10.3234 12.3291 10.5191C12.0671 10.6861 11.7121 10.8425 11.2738 10.9841C10.8461 11.1225 10.4118 11.1927 9.98318 11.1927C9.55456 11.1927 9.12045 11.1225 8.69229 10.9841C8.25437 10.8426 7.89945 10.6862 7.63776 10.5193C7.33427 10.3253 7.05961 10.1483 6.82142 9.99316C6.44056 9.7449 6.24296 9.66113 6.0388 9.66113C5.51878 9.66113 5.03295 9.74918 4.59456 9.92282C4.15481 10.0969 3.7741 10.336 3.46297 10.6337C3.16589 10.9181 2.90084 11.2635 2.67562 11.66C2.45849 12.0423 2.28271 12.441 2.15332 12.8452C2.02835 13.2359 1.92383 13.6633 1.84265 14.1156C1.76224 14.5639 1.70761 14.9892 1.6806 15.3798C1.65405 15.7625 1.64062 16.1597 1.64062 16.5608C1.64062 17.6048 1.9725 18.45 2.62695 19.0733C3.27331 19.6884 4.12856 20.0004 5.1686 20.0004H14.7987C15.8387 20.0004 16.6937 19.6886 17.3402 19.0733C17.9948 18.4505 18.3267 17.6051 18.3267 16.5607C18.3265 16.1577 18.3129 15.7602 18.2862 15.3793Z"></path>
						</svg>
					),
				},
				{
					text: "Programmes",
					route: PROGRAMMES,
					exact: false,
					icon: (
						<svg
							className={`menu-icon`}
							width="28"
							height="28"
							viewBox="0 0 28 28"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect x="4" y="6" width="3" height="3" rx="1" />
							<rect x="9" y="6" width="15" height="3" rx="1" />
							<rect x="4" y="12" width="3" height="3" rx="1" />
							<rect x="9" y="12" width="15" height="3" rx="1" />
							<rect x="4" y="18" width="3" height="3" rx="1" />
							<rect x="9" y="18" width="15" height="3" rx="1" />
						</svg>
					),
				},
				{
					text: "Calendar",
					route: CALENDAR,
					icon: (
						<svg
							className={`menu-icon`}
							width="28"
							height="28"
							viewBox="0 0 28 28"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect x="3" y="13" width="22" height="13" rx="2" />
							<path d="M8 3.5C8 2.67157 8.67157 2 9.5 2C10.3284 2 11 2.67157 11 3.5V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5V3.5Z" />
							<path d="M17 3.5C17 2.67157 17.6716 2 18.5 2C19.3284 2 20 2.67157 20 3.5V5.5C20 6.32843 19.3284 7 18.5 7C17.6716 7 17 6.32843 17 5.5V3.5Z" />
							<path d="M15 5H13V5.5C13 7.433 11.433 9 9.5 9C7.567 9 6 7.433 6 5.5V5H5C3.89543 5 3 5.89543 3 7V9C3 10.1046 3.89543 11 5 11H23C24.1046 11 25 10.1046 25 9V7C25 5.89543 24.1046 5 23 5H22V5.5C22 7.433 20.433 9 18.5 9C16.567 9 15 7.433 15 5.5V5ZM17 5V5.5C17 6.32843 17.6716 7 18.5 7C19.3284 7 20 6.32843 20 5.5V5H17ZM8 5.5V5H11V5.5C11 6.32843 10.3284 7 9.5 7C8.67157 7 8 6.32843 8 5.5Z" />
						</svg>
					),
				},
				{
					text: "Reports",
					route: REPORTS_PAGE,
					icon: (
						<svg
							className={`menu-icon`}
							width="24"
							height="23"
							viewBox="0 0 24 23"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M6.22993 22.705C4.85652 23.4296 3.95577 22.7728 4.21782 21.2385L5.31988 14.7916L0.651805 10.2262C-0.459314 9.13936 -0.115227 8.07712 1.42006 7.85328L7.87151 6.91242L10.7567 1.04713C11.4434 -0.349043 12.5567 -0.349043 13.2434 1.04713L16.1284 6.91242L22.5798 7.85328C24.1153 8.07712 24.4592 9.13936 23.3483 10.2262L18.6802 14.7919L19.7825 21.2388C20.0446 22.773 19.1438 23.4296 17.7704 22.7052L12.0001 19.6615L6.22993 22.705Z" />
						</svg>
					),
				},
		  ]),
];

export const headerMenuNotLogged = [
	{
		text: "Login",
		route: "login",
		icon: (
			<svg
				className={`menu-icon`}
				width="20"
				height="20"
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M9.85722 9.63406C11.1808 9.63406 12.3268 9.15936 13.2633 8.22278C14.1997 7.28635 14.6744 6.14057 14.6744 4.81688C14.6744 3.49364 14.1997 2.34771 13.2631 1.41097C12.3265 0.474699 11.1806 0 9.85722 0C8.53352 0 7.38775 0.474699 6.45132 1.41113C5.51489 2.34756 5.04004 3.49349 5.04004 4.81688C5.04004 6.14057 5.51489 7.2865 6.45147 8.22293C7.3805 9.1592 8.53398 9.63406 9.85722 9.63406Z" />
				<path d="M18.2862 15.3793C18.2592 14.9896 18.2046 14.5645 18.1242 14.1156C18.043 13.6633 17.9385 13.2358 17.8134 12.845C17.6841 12.4411 17.5083 12.0423 17.291 11.66C17.0655 11.2633 16.8006 10.9178 16.5034 10.6336C16.1926 10.3362 15.812 10.0971 15.372 9.92267C14.9334 9.74918 14.4474 9.66129 13.9276 9.66129C13.7234 9.66129 13.526 9.74506 13.1446 9.99332C12.91 10.1464 12.6355 10.3234 12.3291 10.5191C12.0671 10.6861 11.7121 10.8425 11.2738 10.9841C10.8461 11.1225 10.4118 11.1927 9.98318 11.1927C9.55456 11.1927 9.12045 11.1225 8.69229 10.9841C8.25437 10.8426 7.89945 10.6862 7.63776 10.5193C7.33427 10.3253 7.05961 10.1483 6.82142 9.99316C6.44056 9.7449 6.24296 9.66113 6.0388 9.66113C5.51878 9.66113 5.03295 9.74918 4.59456 9.92282C4.15481 10.0969 3.7741 10.336 3.46297 10.6337C3.16589 10.9181 2.90084 11.2635 2.67562 11.66C2.45849 12.0423 2.28271 12.441 2.15332 12.8452C2.02835 13.2359 1.92383 13.6633 1.84265 14.1156C1.76224 14.5639 1.70761 14.9892 1.6806 15.3798C1.65405 15.7625 1.64062 16.1597 1.64062 16.5608C1.64062 17.6048 1.9725 18.45 2.62695 19.0733C3.27331 19.6884 4.12856 20.0004 5.1686 20.0004H14.7987C15.8387 20.0004 16.6937 19.6886 17.3402 19.0733C17.9948 18.4505 18.3267 17.6051 18.3267 16.5607C18.3265 16.1577 18.3129 15.7602 18.2862 15.3793Z" />
			</svg>
		),
	},
];

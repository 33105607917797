import React, { useRef, useState, forwardRef } from "react";
import "./Chatlist.scss";

import ChatList from "./ChatList";
import EmptyChatList from "./EmptyChatList";
import { useDispatch } from "react-redux";
import ChatHeader from "./ChatHeader";
import AddParticipantsToChat from "./AddParticipantsToChat";
import ChatWithUser from "./ChatWithUser";
import {
	clearUserMessages,
	getListRooms,
	getTotalMessages,
	getMessagesSpecifiedUser,
	setChatUser,
} from "../../redux/actions";

const Chat = forwardRef(({ getListRoomsChat, chatUser }, ref) => {
	const dispatch = useDispatch();

	const scrollOnTopChatListRef = useRef();

	const [modalParticipants, setModalParticipants] = useState(false);
	const [userRoom, setUserRoom] = useState(null);

	const handlerParticipantsData = (userData, specifiedUser) => {
		dispatch(setChatUser(userData));
		if (specifiedUser) {
			dispatch(getMessagesSpecifiedUser(userData));
		}
		setModalParticipants(false);
		scrollOnTopList();
	};

	const backToChatList = () => {
		dispatch(setChatUser(null));
		dispatch(clearUserMessages());
		dispatch(getListRooms());
		dispatch(getTotalMessages(null));
	};

	const closeModalParticipants = () => {
		setModalParticipants(false);
	};

	function scrollOnTopList() {
		const currentScrollSectionRef = scrollOnTopChatListRef.current;
		if (currentScrollSectionRef) {
			currentScrollSectionRef.scrollTop = 0;
		}
	}

	return (
		<div className="chat-overlay">
			<div className="chat-wrapper" ref={ref}>
				{!chatUser && (
					<ChatHeader setModalParticipants={setModalParticipants} />
				)}
				{modalParticipants && (
					<AddParticipantsToChat
						closeModalParticipants={closeModalParticipants}
						handlerParticipantsData={handlerParticipantsData}
					/>
				)}
				{chatUser && (
					<ChatWithUser userRoom={userRoom} backToChatList={backToChatList} />
				)}
				{getListRoomsChat && getListRoomsChat.length > 0 ? (
					<>
						{!chatUser && (
							<ChatList
								setUserRoom={setUserRoom}
								getListRoomsChat={getListRoomsChat}
								handlerParticipantsData={handlerParticipantsData}
							/>
						)}
					</>
				) : (
					<>{!chatUser && <EmptyChatList />}</>
				)}
			</div>
		</div>
	);
});

export default Chat;

import React from "react";
import { useNavigate } from "react-router-dom";

import "./CareerCoachingQuestionnaireModal.scss";

import image from "assets/vector/questionnaire/career_coaching.svg";
import { useSelector } from "react-redux";
import routes from "constants/routes";

const CareerCoachingQuestionnaireModal = ({ closeModal }) => {
	const { email } = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const navigate = useNavigate();
	const { DASHBOARD } = routes;

	const onClickHandler = () => {
		closeModal();
		navigate(DASHBOARD);
	};

	React.useEffect(() => {
		const modal__close = document.querySelector(".modal__close");
		modal__close.style.display = "none";
	}, []);

	return (
		<div className="career-coaching-modal">
			<div className="image__wrapper">
				<img src={image} alt="" />
			</div>
			<div className="modal-title">We recieved your answers!</div>
			<div className="modal-description">
				After we process them you will receive our suggestions. We're contacting
				you in <b>24h</b> at your email <b>{email}</b>
			</div>
			<div className="thank-you">Thank you!</div>
			<button className="modal-btn" onClick={() => onClickHandler()}>
				Proceed to Dashboard
			</button>
		</div>
	);
};

export default CareerCoachingQuestionnaireModal;

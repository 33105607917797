import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import routes from "constants/routes";

const { SIGN_IN } = routes;

export const PrivateRoute = () => {
	const accessToken = useSelector((state) => state.auth.authToken);
	// const coachesExist = useSelector((state) => state.coachesList.coachesExist);
	// const getCurrentUserInfo = useSelector(
	// 	(state) => state.currentUserInfo.currentUserInfo
	// );

	const location = useLocation();

	// TODO why this logic? rest.logic always undefined
	// INFO original code export const PrivateRoute = ({ component: Component, ...rest })
	// if (
	// 	getCurrentUserInfo &&
	// 	getCurrentUserInfo.role === "school_admin" &&
	// 	rest.logic &&
	// 	!coachesExist
	// ) {
	// 	return <Navigate to={NOT_FOUND} />;
	// }

	return accessToken ? (
		<Outlet />
	) : (
		<Navigate
			to={{
				pathname: SIGN_IN,
				state: { pathname: location.pathname },
			}}
		/>
	);
};

import React from "react";
import "./UserTable.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import uniqid from "uniqid";
import { ReactComponent as EmptyRatings } from "assets/vector/dashboard/empty-ratings.svg";

const UserTable = ({ users = [] }) => {
	return users.length > 0 ? (
		<Scrollbars
			className="users-table users-table--limit-scroll"
			autoHeight
			autoHeightMin="100%"
			autoHeightMax="100%"
		>
			<div className="users-table__row users-table__row--head">
				<div className="users-table__col">Participant</div>
				<div className="users-table__col">Satisfaction level</div>
				<div className="users-table__col">Attendance rate</div>
				<div className="users-table__col">Expected attendance</div>
			</div>
			{users.map((user) => {
				const {
					avatar,
					satisfaction_level,
					full_name,
					completed_sessions,
					planned_sessions,
					ideal_number_sessions,
				} = user;

				return (
					<div className="users-table__row" key={uniqid()}>
						<div className="users-table__col users-table__col--name">
							{avatar && (
								<img
									src={user.avatar}
									className="users-table__avatar"
									alt="user avatar"
								/>
							)}
							{full_name}
						</div>
						<div className="users-table__col">{`${
							satisfaction_level.includes("0.0")
								? "N / A"
								: `${satisfaction_level}`
						}`}</div>
						<div className="users-table__col">
							{completed_sessions} / {planned_sessions} sessions
						</div>
						<div className="users-table__col">
							{ideal_number_sessions} sessions
						</div>
					</div>
				);
			})}
		</Scrollbars>
	) : (
		<div className="users-table__empty">
			<EmptyRatings className="icon" />
			Data is not available yet
		</div>
	);
};

export default UserTable;

import DashboardPage from "components/pages/Dashboard/Dashboard";
import Participants from "components/pages/Participants/Participants";
import Coaches from "components/pages/Coaches/Coaches";
import CoachesProfile from "components/pages/Coaches/CoachesProfile";
import Programmes from "components/pages/Programmes/Programmes";
import Calendar from "components/pages/CalendarPage/Calendar";
import Evaluations from "components/pages/Evaluations/Evaluations";
import MyProfile from "components/pages/MyProfile/MyProfile";
import EditMyProfile from "components/pages/EditMyProfile/EditMyProfile";
import MySettings from "components/pages/MySettings/MySettings";
import Questionnaire from "components/pages/Questionnaire/Questionnaire";
import ProgrammeStarting from "components/pages/Questionnaire/ProgrammeStarting/ProgrammeStarting";
import QuestionnaireForms from "components/pages/QuestionnaireForms/QuestionnaireForms";
import CoachMatching from "components/pages/Questionnaire/CoachMatching/CoachMatching";
import MatchedCoachProfile from "components/pages/Questionnaire/MatchedCoachProfile/matchedCoachProfile";
import Reports from "components/pages/Reports/Reports";
import NotFound from "components/pages/NotFound/NotFound";
import routes from "constants/routes";
import SingleReport from "components/pages/Reports/SingleReport/SingleReport";
import withLoggedLayout from "../../components/HOC/withLoggedLayout";

const {
	DASHBOARD,
	PARTICIPANTS,
	COACHES,
	COACH_PROFILE,
	PROGRAMMES,
	CALENDAR,
	EVALUATIONS,
	MY_PROFILE,
	EDIT_MY_PROFILE,
	MY_SETTINGS,
	QUESTIONNAIRE,
	PROGRAMME_STARTING,
	CAREER_QUESTIONNAIRE,
	QUESTIONNAIRE_FORM,
	QUESTIONNAIRE_COACH_MATCHING,
	MATCHED_COACH_PROFILE,
	FEEDBACK_PAGE,
	REPORTS_PAGE,
	SINGLE_REPORT,
	NOT_FOUND,
	DECISION_FORM,
} = routes;

export const privateRoutes = [
	{
		path: DASHBOARD,
		component: withLoggedLayout(DashboardPage, true),
		index: true,
	},
	{
		path: `${PARTICIPANTS}/*`,
		component: withLoggedLayout(Participants),
	},
	{ path: COACHES, component: withLoggedLayout(Coaches) },
	{
		path: `${COACH_PROFILE}/*`,
		component: withLoggedLayout(CoachesProfile),
	},
	{ path: `${PROGRAMMES}/*`, component: withLoggedLayout(Programmes) },
	{ path: CALENDAR, component: withLoggedLayout(Calendar) },
	{ path: EVALUATIONS, component: withLoggedLayout(Evaluations) },
	{ path: MY_PROFILE, component: withLoggedLayout(MyProfile) },
	{
		path: `${EDIT_MY_PROFILE}/*`,
		component: withLoggedLayout(EditMyProfile),
	},
	{ path: MY_SETTINGS, component: withLoggedLayout(MySettings) },
	{ path: QUESTIONNAIRE, component: Questionnaire },
	{ path: PROGRAMME_STARTING, component: ProgrammeStarting },
	{ path: CAREER_QUESTIONNAIRE, component: QuestionnaireForms },
	{ path: QUESTIONNAIRE_FORM, component: QuestionnaireForms },
	{ path: QUESTIONNAIRE_COACH_MATCHING, component: CoachMatching },
	{
		path: `${MATCHED_COACH_PROFILE}/*`,
		component: MatchedCoachProfile,
		exact: true,
	},
	{ path: FEEDBACK_PAGE, component: QuestionnaireForms },
	{ path: DECISION_FORM, component: QuestionnaireForms },
	{ path: REPORTS_PAGE, component: withLoggedLayout(Reports) },
	{
		path: `${SINGLE_REPORT}/*`,
		component: withLoggedLayout(SingleReport),
		exact: true,
	},
	{ path: NOT_FOUND, component: withLoggedLayout(NotFound) },
];

import React, { useEffect, useState } from "react";
import "./programmeCard.scss";
import TriggerUploadSvg from "components/icons/TriggerUploadSvg";
import ModalUploadCSV from "components/pages/Programmes/programmeCard/ModalUploadCSV";
import { lock, unlock } from "tua-body-scroll-lock";
import defaultImage from "assets/images/Program/default-image.png";
import { ReactComponent as UploadCsv } from "assets/vector/programmes/upload-csv.svg";

const SchoolAdminProgrammeCard = ({
	id,
	logo,
	started,
	completion_percentage,
	name,
	total_session_activities,
	total_topics,
	is_deactivated,
	progress = 0,
}) => {
	const progressStyles = {
		width: `${completion_percentage}%`,
	};
	const [openTextUploadCSV, setOpenTextUploadCSV] = useState(false);
	const [statusOpenModalUploadCSV, setStatusOpenModalUploadCSV] =
		useState(false);
	const [programIdForModal, setProgramIdForModal] = useState(null);

	const UploadCSV = () => {
		setOpenTextUploadCSV(!openTextUploadCSV);
	};

	const OpenModalUploadCSV = () => {
		setStatusOpenModalUploadCSV(true);
		setProgramIdForModal(id);
	};

	useEffect(() => {
		if (statusOpenModalUploadCSV) {
			lock(document);
		} else {
			unlock(document);
		}
	}, [statusOpenModalUploadCSV]);

	return (
		<>
			<div className={`programme-card ${is_deactivated ? "deactivated" : ""}`}>
				<img
					src={logo ? logo : defaultImage}
					alt="programme-card-image"
					className="programme-card__img"
				/>

				<div className="programme-card__content">
					<h2 className="programme-card__title">
						<span>{name}</span>{" "}
						{is_deactivated && (
							<div className="deactivated-label">Completed</div>
						)}
					</h2>
					<ul className="programme-card__info">
						<li className="programme-card__info-item">{total_topics} Topics</li>
						<li className="programme-card__info-item">
							{total_session_activities} Session activities
						</li>
					</ul>
					<div className="programme-card__progress">
						<div className="programme-card__progress-bar">
							<div
								style={progressStyles}
								className="programme-card__progress-fill"
							></div>
						</div>
						<span className="programme-card__progress-num">
							{completion_percentage}% Completed
						</span>
						{started && !is_deactivated && (
							<div className="wrap-trigger">
								<TriggerUploadSvg
									className={`trigger-upload-svg`}
									color={openTextUploadCSV ? "#E75B32" : "#929292"}
									circleColor={
										openTextUploadCSV ? "rgba(231,91,50,0.2)" : "#F5F5F5"
									}
									onClick={UploadCSV}
								/>
								<div
									className={`open-modal-upload-csv ${
										openTextUploadCSV ? "open" : "close"
									}`}
									onClick={() => {
										OpenModalUploadCSV(id);
									}}
								>
									<div className="icon">
										<UploadCsv />
									</div>
									<div className="text">Upload CSV</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			{programIdForModal && statusOpenModalUploadCSV && (
				<ModalUploadCSV
					programIdForModal={programIdForModal}
					setStatusOpenModalUploadCSV={setStatusOpenModalUploadCSV}
				/>
			)}
		</>
	);
};

export default SchoolAdminProgrammeCard;

import { useEffect } from "react";

const useClickOutside = (refs, handleOnClickOutside) => {
	useEffect(() => {
		const listener = (event) => {
			const refArray = Array.isArray(refs) ? refs : [refs];
			if (
				refArray.some(
					(ref) => ref.current && ref.current.contains(event.target)
				)
			) {
				return;
			}
			handleOnClickOutside(event);
		};
		document.addEventListener("mousedown", listener);
		document.addEventListener("touchstart", listener);
		return () => {
			document.removeEventListener("mousedown", listener);
			document.removeEventListener("touchstart", listener);
		};
	}, [refs, handleOnClickOutside]);
};

export default useClickOutside;

import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import SessionsStats from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/Satisfaction/SessionsStats/SessionsStats";
import GradeStats from "components/pages/Dashboard/SchoolAdmin/Charts/Ratings/Satisfaction/GradesStats/GradeStats";
import { ReactComponent as EmptyRatings } from "assets/vector/dashboard/empty-ratings.svg";

const Satisfaction = ({ satisfaction = {} }) => {
	return Object.keys(satisfaction).length > 0 ? (
		<Scrollbars
			className="users-table users-table--limit-scroll"
			autoHeight
			autoHeightMin="100%"
			autoHeightMax="100%"
		>
			<SessionsStats sessionStats={satisfaction} />
			<GradeStats sessionStats={satisfaction} />
		</Scrollbars>
	) : (
		<div className="users-table__empty">
			<EmptyRatings className="icon" />
			Data is not available yet
		</div>
	);
};

export default Satisfaction;

import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import TextareaAutosize from "react-textarea-autosize";
import "./EditMyProfile.scss";
import CustomMyProfileSelect from "components/CustomFormSelect/CustomMyProfileSelect";
import CustomProfileLanguagesSelect from "components/CustomFormSelect/CustomProfileLanguagesSelect";
import CustomMultiSelect from "components/CustomFormSelect/CustomMultiSelect";
import profile from "services/profile";
import { ReactComponent as CloseSvg } from "assets/vector/close.svg";
import { ReactComponent as CvUploadSvg } from "assets/vector/my-profile/cv-upload.svg";
import { ReactComponent as IconAttachedFileSvg } from "assets/vector/my-profile/icon-attached-file.svg";
import { ReactComponent as ClearCVSvg } from "assets/vector/my-profile/clear-attached-file.svg";
import defaultAva from "assets/vector/default-avatar-light.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "components/button/Button";
import { getInfoAboutCurrentUser } from "../../../redux/actions";
import routes from "constants/routes";
import { getUniqueLanguages } from "components/pages/EditMyProfile/helpers/helpers";
import CustomFormErrorMsg from "components/pages/EditMyProfile/CustomFormError/CustomFormErrorMsg";

export default function CoachesEditProfile({ userInfo }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { MY_PROFILE } = routes;
	const [newLogo, setNewLogo] = useState(null);
	const [checkUploadCVFile, setCheckUploadCVFile] = useState(false);
	const [spinnerLoadingButton, setSpinnerLoadingButton] = React.useState(false);
	const [getCoachingAreas, setCoachingAreas] = useState(null);
	const [getFunctionalAreas, setFunctionalAreas] = useState(null);
	const [getGeographies, setGeographies] = useState(null);
	const [getIndustryBackgrounds, setIndustryBackgrounds] = useState(null);
	const [getLanguages, setLanguages] = useState([]);
	const [getLanguageLevels, setlanguageLevels] = useState(null);
	const [getSeniorityLevels, setSeniorityLevels] = useState(null);
	const [formErrorsFormik, setFormErrorsFormik] = useState(false);
	const userToken = useSelector((state) => state.auth.authToken);
	const hiddenFileInput = useRef(null);
	const hiddenCVFileInput = useRef(null);
	const FILE_SIZE_FOR_AVATAR = 5 * 1024 * 1024; //5242880
	const SUPPORTED_FORMATS_AVATAR = [
		"image/jpg",
		"image/jpeg",
		"image/png",
		"image/svg+xml",
	];
	const handleLoadImgClick = () => {
		hiddenFileInput.current.click();
	};
	const handleLoadImgChange = (event, setFieldValue, handleBlur) => {
		const avatarFileUploaded = event.target.files[0];

		handleBlur(event);

		let avatarFormatIsOk = SUPPORTED_FORMATS_AVATAR.includes(
			avatarFileUploaded.type
		);

		if (avatarFileUploaded) {
			let imageUrl;
			let reader = new FileReader();

			reader.onloadend = () => {
				imageUrl = reader.result;

				let avatarSizeIsReadyToUpload = null;

				if (avatarFileUploaded.size >= FILE_SIZE_FOR_AVATAR) {
					avatarSizeIsReadyToUpload = false;
				} else {
					if (avatarFormatIsOk) {
						setNewLogo(imageUrl);
					}

					avatarSizeIsReadyToUpload = true;
				}

				setFieldValue("avatar", {
					avatarFileUploaded,
					imageUrl,
					avatarSizeIsReadyToUpload,
					avatarFormatIsOk,
				});
			};

			reader.readAsDataURL(avatarFileUploaded);
		}
	};

	const SUPPORTED_FORMATS_CV_FILE = ["application/pdf"];
	const handleAttachCvClick = (event) => {
		hiddenCVFileInput.current.click();
	};
	const handleAttachCvChange = (event, setFieldValue, handleBlur) => {
		const cvFileUploaded = event.target.files[0];
		handleBlur(event);

		if (cvFileUploaded) {
			let cvUrl;
			let reader = new FileReader();

			let cvFormatIsOk = SUPPORTED_FORMATS_CV_FILE.includes(
				cvFileUploaded.type
			);

			reader.onloadend = () => {
				cvUrl = reader.result;
				setCheckUploadCVFile(true);
				setFieldValue("cvFile", { cvFileUploaded, cvUrl, cvFormatIsOk });
			};

			reader.readAsDataURL(cvFileUploaded);
		}
	};

	const clearAttachCvClick = (event, setFieldValue) => {
		const clearCVFile = (event.target.value = null);
		setFieldValue("cvFile", clearCVFile);
		setCheckUploadCVFile(false);
	};

	const updateUserProfileData = (
		userId,
		values,
		token,
		setStatus,
		setErrors
	) => {
		profile
			.updateCoachProfile(userId, values, token, setStatus, setErrors)
			.then((response) => {
				if (response.data.status === "Success") {
					setStatus({
						show: true,
						sent: true,
						msg: "Your profile has been successfully updated!",
					});
					setSpinnerLoadingButton(true);
					setTimeout(() => {
						setSpinnerLoadingButton(false);
						setStatus({ show: false });

						navigate(MY_PROFILE);
					}, 2000);
					dispatch(getInfoAboutCurrentUser());
				}
			})
			.catch((error) => {
				console.log(error);
				setStatus({
					show: true,
					sent: false,
					msg: `Error! ${error.response.data.message}. Please try again later.`,
				});

				setTimeout(() => {
					setSpinnerLoadingButton(false);
					setStatus({ show: false });
				}, 4000);
			});
	};

	const getUserProfileDefinedData = () => {
		profile
			.getDefinedData(userToken)
			.then((response) => {
				setCoachingAreas(response.coachingAreas);
				setFunctionalAreas(response.functionalAreas);
				setGeographies(response.geographies);
				setIndustryBackgrounds(response.industryBackgrounds);
				setLanguages(response.languages);
				setlanguageLevels(response.languageLevels);
				setSeniorityLevels(response.seniorityLevels);
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		getUserProfileDefinedData();
	}, []);

	useEffect(() => {
		if (userInfo) {
			if (userInfo.cv) {
				setCheckUploadCVFile(true);
			} else {
				setCheckUploadCVFile(false);
			}

			setNewLogo(userInfo.avatar ? userInfo.avatar : defaultAva);
		}
	}, [userInfo]);

	return (
		<Formik
			validateOnMount={true}
			initialValues={{
				avatar: userInfo.avatar,
				cvFile: userInfo.cv ? userInfo.cv : "",
				firstName: userInfo.name ? userInfo.name : "",
				lastName: userInfo.surname ? userInfo.surname : "",
				location: userInfo.location ? userInfo.location : "",
				seniority_level: userInfo.seniority_level
					? userInfo.seniority_level.id
					: null,
				summary: userInfo.summary ? userInfo.summary : "",
				education: userInfo.education,
				coachingExperienceYears: userInfo.coaching_experience.years
					? userInfo.coaching_experience.years
					: "",
				coachingExperienceHours: userInfo.coaching_experience.hours
					? userInfo.coaching_experience.hours
					: "",
				certification: userInfo.certification ? userInfo.certification : "",
				functionalArea: userInfo.functional_areas,
				coachingInfo: userInfo.coaching_areas,
				industryBackground: userInfo.industry_background,
				geography: userInfo.geography,
				languages: userInfo.languages,
				languagesLevel: userInfo.languages.level,
				work_experience: userInfo.work_experience
					? userInfo.work_experience.map(function (element) {
							return {
								company: element.company,
								position: element.position,
								responsibilities: element.responsibilities,
								date_from: new Date(
									element.date_from === "Invalid date" || !element.date_from
										? null
										: element.date_from
								),
								date_to: new Date(
									element.date_to === "Invalid date" || !element.date_to
										? new Date()
										: element.date_to
								),
							};
					  })
					: [],
				publications: userInfo.publications
					? userInfo.publications.map(function (element) {
							return {
								name: element.name,
								publishing_house: element.publishing_house,
								author: element.author,
								overview: element.overview,
							};
					  })
					: "",
			}}
			validationSchema={Yup.object({
				firstName: Yup.string()
					.min(3, "Must be 3 characters or more")
					.required("Required"),
				lastName: Yup.string()
					.min(3, "Must be 3 characters or more")
					.required("Required"),
				avatar: Yup.mixed()
					.test("fileSize", "File too large", (value) => {
						if (value) {
							if (typeof value === "string") {
								return true;
							} else if (value.avatarSizeIsReadyToUpload !== undefined) {
								return value.avatarSizeIsReadyToUpload;
							}
						}
					})
					.test(
						"fileType",
						"Unsupported file type. Only .jpg, .jpeg, .png or .svg.",
						(value) => {
							if (value) {
								if (typeof value === "string") {
									return true;
								} else if (value.avatarFormatIsOk !== undefined) {
									return value.avatarFormatIsOk;
								}
							}
						}
					),

				cvFile: Yup.mixed().test(
					"fileType",
					"Unsupported file type. Only PDF.",
					(value) => {
						if (typeof value === "string") {
							return true;
						} else if (value != null) {
							return value.cvFormatIsOk;
						} else {
							return true;
						}
					}
				),
				work_experience: Yup.array().of(
					Yup.object({
						company: Yup.string()
							.min(3, "Must be 3 characters or more")
							.required("Required"),
						position: Yup.string()
							.min(3, "Must be 3 characters or more")
							.required("Required"),
						responsibilities: Yup.string()
							.min(3, "Must be 3 characters or more")
							.required("Required"),
						date_from: Yup.date().nullable(true).required("Required"),
					})
				),
				publications: Yup.array().of(
					Yup.object({
						name: Yup.string()
							.min(3, "Must be 3 characters or more")
							.required("Required"),
						publishing_house: Yup.string()
							.min(3, "Must be 3 characters or more")
							.required("Required"),
						author: Yup.string()
							.min(3, "Must be 3 characters or more")
							.required("Required"),
						overview: Yup.string()
							.min(3, "Must be 3 characters or more")
							.required("Required"),
					})
				),
				languages: Yup.array().of(
					Yup.object({
						level: Yup.mixed().required("Required"),
						id: Yup.mixed().required("Required"),
					})
				),
				summary: Yup.string()
					.min(3, "Must be 3 characters or more")
					.max(2000, "The summary must not be greater than 2000 characters."),
			})}
			onSubmit={(values, { setErrors, setSubmitting, setStatus }) => {
				setSubmitting(false);
				setStatus(false);
				setFormErrorsFormik(false);
				updateUserProfileData(
					userInfo.id,
					values,
					userToken,
					setStatus,
					setErrors
				);
			}}
		>
			{({
				values,
				handleSubmit,
				errors,
				touched,
				isSubmitting,
				dirty,
				setFieldValue,
				handleBlur,
				status,
				setStatus,
				isValid,
				...props
			}) => {
				return (
					<Form>
						<div className="section-info-block personal-information">
							<h3 className={`section-title`}>Personal information</h3>

							<div
								className={`avatar ${
									errors.avatar && touched.avatar ? "input-error" : ""
								}`}
							>
								<div className="image">
									<img src={newLogo} alt="avatar" />
								</div>

								<div className="upload-image-wrap">
									<div className="load-img" onClick={handleLoadImgClick}>
										Change photo
									</div>

									<input
										accept="image/*"
										type="file"
										ref={hiddenFileInput}
										name="avatar"
										onChange={(event) =>
											handleLoadImgChange(event, setFieldValue, handleBlur)
										}
										style={{ display: "none" }}
									/>

									<div className={`prompt`}>
										Format .jpg, .png or .svg
										<br />
										File size up to 5MB
									</div>

									<ErrorMessage
										className={`profile-row__error`}
										component="div"
										name="avatar"
									/>
								</div>
							</div>

							<div className="cv-file">
								<div
									className={`attach-cv ${
										errors.cvFile && touched.cvFile ? "input-error" : ""
									}`}
								>
									{!checkUploadCVFile && (
										<div className="default-show" onClick={handleAttachCvClick}>
											<CvUploadSvg className={`icon-cv-upload`} /> Attach CV
										</div>
									)}

									{checkUploadCVFile && (
										<div
											className="uploaded-cv-file"
											onClick={(event) =>
												clearAttachCvClick(event, setFieldValue)
											}
										>
											<IconAttachedFileSvg className={`icon-attached`} />
											<div className="text">My CV</div>
											{/*{newCVFile}*/}
											<ClearCVSvg className={`icon-clear-cv`} />
										</div>
									)}
								</div>

								<input
									accept="application/pdf"
									type="file"
									ref={hiddenCVFileInput}
									name="cvFile"
									onChange={(event) =>
										handleAttachCvChange(event, setFieldValue, handleBlur)
									}
									style={{ display: "none" }}
								/>

								<ErrorMessage
									className={`profile-row__error`}
									component="div"
									name="cvFile"
								/>
							</div>

							<div
								className={`profile-row first-name ${
									errors.firstName && touched.firstName ? "input-error" : ""
								}`}
							>
								<label htmlFor={"firstName"} className="profile-row__label">
									First name
								</label>
								<Field
									name="firstName"
									type="text"
									placeholder="First name"
									className={`profile-row__input`}
								/>
								<ErrorMessage
									className={`profile-row__error`}
									component="div"
									name="firstName"
								/>
							</div>

							<div
								className={`profile-row last-name ${
									errors.lastName && touched.lastName ? "input-error" : ""
								}`}
							>
								<label htmlFor={"lastName"} className="profile-row__label">
									Last name
								</label>
								<Field
									name="lastName"
									type="text"
									placeholder="First name"
									className={`profile-row__input`}
								/>
								<ErrorMessage
									className={`profile-row__error`}
									component="div"
									name="lastName"
								/>
							</div>

							<div className={`profile-row location`}>
								<label htmlFor={"location"} className="profile-row__label">
									Location
								</label>
								<Field
									name="location"
									type="text"
									placeholder="Location"
									className={`profile-row__input`}
								/>
							</div>
						</div>

						<div className="section-info-block languages">
							<h3 className={`section-title`}>Languages</h3>

							<FieldArray name="languages">
								{({ remove, push }) => (
									<div className="languages-list">
										{values.languages.length > 0 &&
											values.languages.map((language, index) => {
												return (
													<div className="item" key={index}>
														<div
															className={`language ${
																errors &&
																errors.languages &&
																errors.languages[index] &&
																errors.languages[index].id &&
																touched &&
																touched.languages &&
																touched.languages[index] &&
																touched.languages[index].id
																	? "input-error"
																	: ""
															}`}
														>
															<label
																htmlFor={`languages.${index}.id`}
																className="profile-row__label"
															>
																Language
															</label>
															<Field
																name={`languages.${index}.id`}
																options={getUniqueLanguages(
																	getLanguages,
																	values.languages
																)}
																defaultOptions={getLanguages}
																placeholder="Choose language..."
																component={CustomProfileLanguagesSelect}
																defaultValue={language}
															/>
															<ErrorMessage
																className={`profile-row__error`}
																component="div"
																name={`languages.${index}.id`}
															/>
														</div>

														<div
															className={`level ${
																errors &&
																errors.languages &&
																errors.languages[index] &&
																errors.languages[index].level &&
																touched &&
																touched.languages &&
																touched.languages[index] &&
																touched.languages[index].level
																	? "input-error"
																	: ""
															}`}
														>
															<label
																htmlFor={`languages.${index}.level`}
																className="profile-row__label"
															>
																Level
															</label>
															<Field
																name={`languages.${index}.level`}
																options={getLanguageLevels}
																placeholder="Choose level..."
																component={CustomProfileLanguagesSelect}
																defaultValue={language.level}
															/>
															<ErrorMessage
																className={`profile-row__error`}
																component="div"
																name={`languages.${index}.level`}
															/>
														</div>

														<div className="remove-row">
															<div
																className="remove-item-language"
																onClick={() => {
																	remove(index);
																}}
															>
																<CloseSvg />{" "}
															</div>
														</div>
													</div>
												);
											})}
										<div
											className="add-new-info add-language"
											onClick={() => push({ id: "", level: "" })}
										>
											+ Add another language
										</div>
									</div>
								)}
							</FieldArray>
						</div>

						<div className="section-info-block seniority-level">
							<h3 className={`section-title`}>Seniority Level</h3>

							<div className={`profile-row seniority-level-list`}>
								<label
									htmlFor={"seniority_level"}
									className="profile-row__label"
								>
									Seniority
								</label>

								<Field
									name="seniority_level"
									options={getSeniorityLevels}
									placeholder="Choose your option..."
									component={CustomMyProfileSelect}
									defaultValue={userInfo.seniority_level}
								/>
							</div>
						</div>

						<div className="section-info-block work-experience">
							<h3 className={`section-title`}>Work experience</h3>

							<div className="work-experience-list">
								<FieldArray name="work_experience">
									{({ remove, push }) => (
										<>
											{values.work_experience.length > 0 &&
												values.work_experience.map((work_experience, index) => {
													return (
														<div className="item" key={index}>
															<h4 className={`counter-work-experience`}>
																Work experience {index + 1}
															</h4>

															<div className={`company item-row`}>
																<label
																	htmlFor={`work_experience.${index}.company`}
																	className="profile-row__label"
																>
																	Company
																</label>

																<Field
																	name={`work_experience.${index}.company`}
																	type="text"
																	className={`profile-row__input ${
																		errors.work_experience &&
																		errors.work_experience[index]?.company &&
																		(values.work_experience[index]?.company ||
																			(touched.work_experience &&
																				touched.work_experience[index]
																					?.company))
																			? "input-error"
																			: ""
																	}`}
																/>
																<CustomFormErrorMsg
																	show={
																		errors.work_experience &&
																		errors.work_experience[index]?.company &&
																		(values.work_experience[index]?.company ||
																			(touched.work_experience &&
																				touched.work_experience[index]
																					?.company))
																	}
																	className="profile-row__error"
																	message={
																		errors.work_experience &&
																		errors.work_experience[index]?.company
																	}
																/>
															</div>

															<div className="position item-row">
																<label
																	htmlFor={`work_experience.${index}.position`}
																	className="profile-row__label"
																>
																	Position
																</label>

																<Field
																	name={`work_experience.${index}.position`}
																	type="text"
																	className={`profile-row__input ${
																		errors.work_experience &&
																		errors.work_experience[index]?.position &&
																		(values.work_experience[index]?.position ||
																			(touched.work_experience &&
																				touched.work_experience[index]
																					?.position))
																			? "input-error"
																			: ""
																	}`}
																/>
																<CustomFormErrorMsg
																	show={
																		errors.work_experience &&
																		errors.work_experience[index]?.position &&
																		(values.work_experience[index]?.position ||
																			(touched.work_experience &&
																				touched.work_experience[index]
																					?.position))
																	}
																	className="profile-row__error"
																	message={
																		errors.work_experience &&
																		errors.work_experience[index]?.position
																	}
																/>
															</div>

															<div className="responsibilities item-row">
																<label
																	htmlFor={`work_experience.${index}.responsibilities`}
																	className="profile-row__label"
																>
																	Responsibilities
																</label>

																<Field
																	name={`work_experience.${index}.responsibilities`}
																	type="text"
																	className={`profile-row__input ${
																		errors.work_experience &&
																		errors.work_experience[index]
																			?.responsibilities &&
																		(values.work_experience[index]
																			?.responsibilities ||
																			(touched.work_experience &&
																				touched.work_experience[index]
																					?.responsibilities))
																			? "input-error"
																			: ""
																	}`}
																/>
																<CustomFormErrorMsg
																	show={
																		errors.work_experience &&
																		errors.work_experience[index]
																			?.responsibilities &&
																		(values.work_experience[index]
																			?.responsibilities ||
																			(touched.work_experience &&
																				touched.work_experience[index]
																					?.responsibilities))
																	}
																	className="profile-row__error"
																	message={
																		errors.work_experience &&
																		errors.work_experience[index]
																			?.responsibilities
																	}
																/>
															</div>

															<div className="date-picker">
																<div className="date-from item-row">
																	<label
																		htmlFor={`work_experience.${index}.date_from`}
																		className="profile-row__label"
																	>
																		From
																	</label>

																	<DatePicker
																		// wrapperClassName=""
																		name={`work_experience[${index}]['date_from']`}
																		selected={
																			getIn(
																				values,
																				`work_experience[${index}]['date_from']`
																			) || ""
																		}
																		value={
																			getIn(
																				values,
																				`work_experience[${index}]['date_from']`
																			) || ""
																		}
																		onChange={(e) =>
																			setFieldValue(
																				`work_experience[${index}]['date_from']`,
																				e
																			)
																		}
																		selectsStart
																		startDate={new Date()}
																		endDate={new Date()}
																		maxDate={work_experience.date_to}
																		dateFormat="MM/yyyy"
																		showMonthYearPicker
																		className={`experience-row-input ${
																			errors.work_experience &&
																			errors.work_experience[index]
																				?.date_from &&
																			(values.work_experience[index]
																				?.date_from ||
																				(touched.work_experience &&
																					touched.work_experience[index]
																						?.date_from))
																				? "input-error"
																				: ""
																		}`}
																	/>
																	<CustomFormErrorMsg
																		show={
																			errors.work_experience &&
																			errors.work_experience[index]
																				?.date_from &&
																			(values.work_experience[index]
																				?.date_from ||
																				(touched.work_experience &&
																					touched.work_experience[index]
																						?.date_from))
																		}
																		className="profile-row__error"
																		message={
																			errors.work_experience &&
																			errors.work_experience[index]?.date_from
																		}
																	/>
																</div>

																<div className="date-to item-row">
																	<label
																		htmlFor={`work_experience.${index}.date_to`}
																		className="profile-row__label"
																	>
																		To
																	</label>

																	<DatePicker
																		name={`work_experience[${index}]['date_to']`}
																		selected={
																			getIn(
																				values,
																				`work_experience[${index}]['date_to']`
																			) || ""
																		}
																		value={
																			getIn(
																				values,
																				`work_experience[${index}]['date_to']`
																			) || ""
																		}
																		onChange={(e) =>
																			setFieldValue(
																				`work_experience[${index}]['date_to']`,
																				e
																			)
																		}
																		selectsEnd
																		startDate={new Date()}
																		endDate={new Date()}
																		minDate={work_experience.date_from}
																		maxDate={new Date()}
																		firstDayOfWeek={1}
																		dateFormat="MM/yyyy"
																		showMonthYearPicker
																		className={`experience-row-input`}
																		placeholderText={`Present time`}
																	/>
																</div>
															</div>

															<div className="remove-row">
																<div
																	className="remove-item-work-experience"
																	onClick={() => {
																		remove(index);
																	}}
																>
																	<CloseSvg />
																</div>
															</div>
														</div>
													);
												})}

											<div
												className="add-new-info add-work-experience"
												onClick={() => {
													push({
														company: "",
														position: "",
														responsibilities: "",
														date_from: "",
														date_to: "",
													});
													setFormErrorsFormik(false);
												}}
											>
												+ Add work experience
											</div>
										</>
									)}
								</FieldArray>
							</div>
						</div>

						<div className="section-info-block publications">
							<h3 className={`section-title`}>Publications</h3>

							<div className="publications-list">
								<FieldArray name="publications">
									{({ remove, push }) => (
										<>
											{values.publications.length > 0 &&
												values.publications.map((publications, index) => {
													return (
														<div className="item" key={index}>
															<h4 className={`counter-publication`}>
																Publication {index + 1}
															</h4>

															<div className="name item-row">
																<label
																	htmlFor={`publications.${index}.name`}
																	className="profile-row__label"
																>
																	Name
																</label>

																<Field
																	name={`publications.${index}.name`}
																	type="text"
																	className={`profile-row__input ${
																		errors.publications &&
																		errors.publications[index]?.name &&
																		(values.publications[index]?.name ||
																			(touched.publications &&
																				touched.publications[index]?.name))
																			? "input-error"
																			: ""
																	}`}
																/>
																<CustomFormErrorMsg
																	show={
																		errors.publications &&
																		errors.publications[index]?.name &&
																		(values.publications[index]?.name ||
																			(touched.publications &&
																				touched.publications[index]?.name))
																	}
																	className="profile-row__error"
																	message={
																		errors.publications &&
																		errors.publications[index]?.name
																	}
																/>
															</div>

															<div className="publishing-house item-row">
																<label
																	htmlFor={`publications.${index}.publishing_house`}
																	className="profile-row__label"
																>
																	Publishing house
																</label>

																<Field
																	name={`publications.${index}.publishing_house`}
																	type="text"
																	className={`profile-row__input ${
																		errors.publications &&
																		errors.publications[index]
																			?.publishing_house &&
																		(values.publications[index]
																			?.publishing_house ||
																			(touched.publications &&
																				touched.publications[index]
																					?.publishing_house))
																			? "input-error"
																			: ""
																	}`}
																/>
																<CustomFormErrorMsg
																	show={
																		errors.publications &&
																		errors.publications[index]
																			?.publishing_house &&
																		(values.publications[index]
																			?.publishing_house ||
																			(touched.publications &&
																				touched.publications[index]
																					?.publishing_house))
																	}
																	className="profile-row__error"
																	message={
																		errors.publications &&
																		errors.publications[index]?.publishing_house
																	}
																/>
															</div>

															<div className="author item-row">
																<label
																	htmlFor={`publications.${index}.author`}
																	className="profile-row__label"
																>
																	Author(s)
																</label>

																<Field
																	name={`publications.${index}.author`}
																	type="text"
																	className={`profile-row__input ${
																		errors.publications &&
																		errors.publications[index]?.author &&
																		(values.publications[index]?.author ||
																			(touched.publications &&
																				touched.publications[index]?.author))
																			? "input-error"
																			: ""
																	}`}
																/>
																<CustomFormErrorMsg
																	show={
																		errors.publications &&
																		errors.publications[index]?.author &&
																		(values.publications[index]?.author ||
																			(touched.publications &&
																				touched.publications[index]?.author))
																	}
																	className="profile-row__error"
																	message={
																		errors.publications &&
																		errors.publications[index]?.author
																	}
																/>
															</div>

															<div className="overview item-row">
																<label
																	htmlFor={`publications.${index}.overview`}
																	className="profile-row__label"
																>
																	Overview
																</label>
																<TextareaAutosize
																	name={`publications.${index}.overview`}
																	className={`profile-row__textarea ${
																		errors.publications &&
																		errors.publications[index]?.overview &&
																		(values.publications[index]?.overview ||
																			(touched.publications &&
																				touched.publications[index]?.overview))
																			? "input-error"
																			: ""
																	}`}
																	onChange={(event) =>
																		setFieldValue(
																			`publications[${index}].overview`,
																			event.currentTarget.value
																		)
																	}
																	defaultValue={publications.overview}
																/>
																<CustomFormErrorMsg
																	show={
																		errors.publications &&
																		errors.publications[index]?.overview &&
																		(values.publications[index]?.overview ||
																			(touched.publications &&
																				touched.publications[index]?.overview))
																	}
																	className="profile-row__error"
																	message={
																		errors.publications &&
																		errors.publications[index]?.overview
																	}
																/>
															</div>

															<div className="remove-row">
																<div
																	className="remove-item-publications"
																	onClick={() => {
																		remove(index);
																	}}
																>
																	<CloseSvg />
																</div>
															</div>
														</div>
													);
												})}

											<div
												className="add-new-info add-publications"
												onClick={() => {
													push({
														name: "",
														publishing_house: "",
														author: "",
														overview: "",
													});
													setFormErrorsFormik(false);
												}}
											>
												+ Add another publication
											</div>
										</>
									)}
								</FieldArray>
							</div>
						</div>

						<div className="section-info-block functional-area">
							<h3 className={`section-title`}>Functional Area</h3>

							<div className="functional-area-list">
								<label
									htmlFor={"functionalArea"}
									className="profile-row__label"
								>
									Functional Area <span className={`prompt`}>Start typing</span>
								</label>
								<Field
									name="functionalArea"
									options={getFunctionalAreas}
									placeholder="Choose your option..."
									component={CustomMultiSelect}
									defaultValue={userInfo.functional_areas}
								/>
							</div>
						</div>

						<div className="section-info-block coaching-info">
							<h3 className={`section-title`}>Coaching information</h3>
							<label htmlFor={`coachingInfo`} className="profile-row__label">
								Coaching competencies
							</label>
							<div className="coaching-information-list">
								<Field
									name="coachingInfo"
									options={getCoachingAreas}
									defaultValue={userInfo.coaching_areas}
									placeholder="Choose your option..."
									component={CustomMultiSelect}
								/>
							</div>

							<div className="additional-info">
								<div className="coaching-experience">
									<div className="section-coaching-experience">
										Coaching experience
									</div>

									<div className="years">
										<div className="item-row">
											<label
												htmlFor={`coachingExperienceYears`}
												className="profile-row__label"
											>
												Years
											</label>

											<Field
												name={`coachingExperienceYears`}
												type="number"
												placeholder="Years"
												className={`profile-row__input`}
											/>
										</div>
									</div>

									<div className="hours">
										<div className="item-row">
											<label
												htmlFor={`coachingExperienceHours`}
												className="profile-row__label"
											>
												Hours
											</label>

											<Field
												name={`coachingExperienceHours`}
												type="number"
												placeholder="Hours"
												className={`profile-row__input`}
											/>
										</div>
									</div>
								</div>

								<div className="certification-block">
									<div className={`profile-row certification`}>
										<label
											htmlFor={"certification"}
											className="profile-row__label"
										>
											Certifications
										</label>
										<Field
											name="certification"
											type="text"
											placeholder="Certifications"
											className={`profile-row__input`}
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="section-info-block industry-background">
							<h3 className={`section-title`}>Industry Background</h3>

							<div className="industry-background-list">
								<label
									htmlFor={"functionalArea"}
									className="profile-row__label"
								>
									Industry Background{" "}
									<span className={`prompt`}>Start typing</span>
								</label>
								<Field
									name="industryBackground"
									options={getIndustryBackgrounds}
									placeholder="Choose your option..."
									component={CustomMultiSelect}
									defaultValue={userInfo.industry_background}
								/>
							</div>
						</div>

						<div className="section-info-block geography">
							<h3 className={`section-title`}>Geography</h3>

							<div className="geography-list">
								<Field
									name="geography"
									options={getGeographies}
									defaultValue={userInfo.geography}
									placeholder="Choose your option..."
									component={CustomMultiSelect}
								/>
							</div>
						</div>

						<div className={`section-info-block summary`}>
							<h3 className={`section-title`}>Summary</h3>

							<div className={`profile-row goal-text`}>
								<Field
									name="summary"
									className={`profile-row__textarea ${
										errors.summary ? "input-error" : ""
									}`}
									onChange={(event) =>
										setFieldValue("summary", event.currentTarget.value)
									}
									defaultValue={userInfo.summary}
									component={TextareaAutosize}
								/>

								{errors && errors.summary && (
									<div className="profile-row__error">{errors?.summary}</div>
								)}
							</div>
						</div>

						{status && status.show && (
							<div className="status-messages">
								<p
									className={`alert ${
										status.sent ? "alert-success" : "alert-error"
									}`}
								>
									{status.msg}
								</p>
							</div>
						)}

						{!isValid && dirty && formErrorsFormik && (
							<div className="status-messages">
								<p className={`alert alert-error`}>
									Please, fill out all required fields
								</p>
							</div>
						)}

						<div className="save-block-wrapper">
							<Button
								type="submit"
								className="save-personal-info"
								loading={spinnerLoadingButton}
								disabled={isSubmitting || !dirty}
								onClick={() => {
									setFormErrorsFormik(true);
								}}
							>
								Save changes
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
}

import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Footer from "components/pages/Auth/Footer/Footer";
import Header from "components/pages/Auth/Header/Header";
import FeedbackForm from "./Forms/FeedbackForm";
import QuestionnaireForm from "./Forms/QuestionnaireForm";
import routes from "../../../constants/routes";
import "./QuestionnaireForms.scss";
import DecisionForm from "./Forms/DecisionForm";

// for ILTB
// import decor_image1 from 'assets/vector/auth-page/decor-3.svg';
// import decor_image2 from 'assets/vector/auth-page/auth-decor-lines.svg';
// import decor_image_main from 'assets/images/FeedbackForm/Frame.png';

const QuestionnaireForms = () => {
	const { QUESTIONNAIRE_FORM, DECISION_FORM } = routes;

	const location = useLocation();
	const { survey, role } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const FormTemplate = () => {
		if (location.pathname === QUESTIONNAIRE_FORM) {
			return <QuestionnaireForm />;
		}

		if (location.pathname === DECISION_FORM) {
			return <DecisionForm />;
		}

		return <FeedbackForm />;
	};

	if (
		(!survey?.required || survey?.skip) &&
		location.pathname === QUESTIONNAIRE_FORM &&
		role === "participant"
	) {
		return <Navigate to="/" />;
	}

	return (
		<section className="feedback-page">
			<Header />
			{/* for ILTB */}
			{/* <img className="decor-image-left" src={decor_image1} alt="" /> */}
			{/* <img className="decor-image-right" src={decor_image2} alt="" /> */}
			{/* <img className="decor-image-main" src={decor_image_main} alt="" /> */}
			<FormTemplate />
			<Footer />
		</section>
	);
};

export default QuestionnaireForms;

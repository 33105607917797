import React from "react";
import { Link } from "react-router-dom";
import "./programmeCard.scss";
import defaultImage from "assets/images/Program/default-image.png";

const ParticipantsProgrammeCard = ({
	id,
	logo_url,
	name,
	completion_percentage,
	total_session_activities,
	total_topics,
	progress = 0,
}) => {
	const progressStyles = {
		width: `${completion_percentage}%`,
	};

	return (
		<Link to={`single-program/${id}`} className="programme-card">
			<img
				src={logo_url ? logo_url : defaultImage}
				alt="programme-card"
				className="programme-card__img"
			/>

			<div className="programme-card__content">
				<div className="programme-card__title">{name}</div>
				<ul className="programme-card__info">
					<li className="programme-card__info-item">{total_topics} Topics</li>
					<li className="programme-card__info-item">
						{total_session_activities} Session activities
					</li>
				</ul>
				<div className="programme-card__progress">
					<div className="programme-card__progress-bar">
						<div
							style={progressStyles}
							className="programme-card__progress-fill"
						></div>
					</div>
					<span className="programme-card__progress-num">
						{completion_percentage}% Completed
					</span>
				</div>
			</div>
		</Link>
	);
};

export default ParticipantsProgrammeCard;

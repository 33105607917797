import React from "react";
import DashboardLinks from "./DashboardLinks/DashboardLinks";
import ProgramsProgress from "./ProgramsProgress/ProgramsProgress";
import "./ParticipantDashboard.scss";
import Activities from "./Activities/Activities";
import ActionItems from "./ActionItems/ActionItems";
import UpcomingSessions from "./UpcomingSessions/UpcomingSessions";
import RecentChats from "./RecentChats/RecentChats";

const ParticipantDashboard = () => {
	return (
		<div className="section-wrap participant-dashboard">
			<div className="participant-dashboard__main-section">
				<ProgramsProgress />
				<Activities />
				<ActionItems />
			</div>
			<div className="participant-dashboard__side-section">
				<DashboardLinks />
				<UpcomingSessions />
				<RecentChats />
			</div>
		</div>
	);
};

export default ParticipantDashboard;

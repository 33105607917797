import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import SelectProgramm from "components/icons/SelectProgramm";
import {
	getListUserSchoolProgramsOnScroll,
	setCurrentSchoolProgram,
} from "../../../../../redux/actions";
import useClickOutside from "hooks/useClickOutside";

import "./programsDropdown.scss";
import { useObserver } from "hooks/useObserver";
import ProgramStatusIcon from "./programStatusIcon";

export default function ProgramsDropdown() {
	const programsLimit = 10;
	const dispatch = useDispatch();
	const listUserSchoolPrograms = useSelector(
		(state) => state.dashboard.listUserSchoolPrograms
	);
	const listUserSchoolProgramsTotal = useSelector(
		(state) => state.dashboard.listUserSchoolProgramsTotal
	);
	const { listUserSchoolProgramsLoading } = useSelector(
		(state) => state.dashboard
	);

	const get_dashboard_user_program = localStorage.getItem(
		"dashboard_user_program"
	);

	const [statusOpenUserProgram, setStatusOpenUserProgram] = useState(false);
	const [defaultUserProgram, setDefaultUserProgram] = useState(null);
	const [programsOffset, setProgramsOffset] = useState(0);

	const currentProgram =
		JSON.parse(get_dashboard_user_program) || listUserSchoolPrograms[0];

	const dropdownRef = useRef();
	const lastElement = useRef();

	const OpenUserProgram = () => {
		setStatusOpenUserProgram(!statusOpenUserProgram);
	};

	const setIDProgram = (program) => {
		setStatusOpenUserProgram(!statusOpenUserProgram);
		setDefaultUserProgram(program);

		dispatch(setCurrentSchoolProgram(program));

		localStorage.setItem("dashboard_user_program", JSON.stringify(program));
	};

	useClickOutside(dropdownRef, () => {
		if (statusOpenUserProgram) setStatusOpenUserProgram(false);
	});

	useEffect(() => {
		if (listUserSchoolPrograms) {
			setDefaultUserProgram(listUserSchoolPrograms[0]);
		}
		if (get_dashboard_user_program) {
			dispatch(setCurrentSchoolProgram(JSON.parse(get_dashboard_user_program)));
		} else {
			dispatch(setCurrentSchoolProgram(listUserSchoolPrograms[0]));
		}
	}, [get_dashboard_user_program]);

	useEffect(() => {
		if (get_dashboard_user_program) {
			setDefaultUserProgram(JSON.parse(get_dashboard_user_program));
		}
	}, [get_dashboard_user_program]);

	useObserver(
		lastElement,
		programsOffset + programsLimit <= listUserSchoolProgramsTotal,
		listUserSchoolProgramsLoading,
		() => {
			setProgramsOffset(programsOffset + programsLimit);
		}
	);

	useEffect(() => {
		const params = {
			take: programsLimit,
			offset: programsOffset,
		};

		dispatch(getListUserSchoolProgramsOnScroll(params));
	}, [programsOffset]);

	return (
		<div className="program-dropdown" ref={dropdownRef}>
			<div className="select-user-program" onClick={OpenUserProgram}>
				<div className="program-name">
					<ProgramStatusIcon
						color={defaultUserProgram?.is_deactivated ? "#3D8B82" : "#CDCBCB"}
					/>
					<span>{defaultUserProgram?.name}</span>
				</div>
				<div className="dropdown__icon">
					<SelectProgramm
						color="#342F52"
						className={`dropdown-arrow ${
							statusOpenUserProgram && "rotate-arrow"
						}`}
					/>
					<div
						className={`program-list ${statusOpenUserProgram ? "open" : ""} `}
					>
						<Scrollbars
							className="program-list-wrap"
							autoHeight
							autoHeightMin="100%"
							autoHeightMax="100%"
						>
							{listUserSchoolPrograms &&
								listUserSchoolPrograms.map((program) => {
									return (
										<div
											key={program.id}
											className={`item ${
												currentProgram?.name === program.name ? "active" : ""
											}`}
											onClick={() => setIDProgram(program)}
										>
											<ProgramStatusIcon
												color={program.is_deactivated ? "#3D8B82" : "#CDCBCB"}
											/>
											{program.name}
										</div>
									);
								})}
							<div className="last-element" ref={lastElement}></div>
						</Scrollbars>
					</div>
				</div>
			</div>
		</div>
	);
}

import Button from "components/button/Button";
import React from "react";
import styles from "../DecisionBox.module.scss";
import Select, { components } from "react-select";
import { customDecisionSelectStyles } from "helpers/customSelectStyles";
import { ReactComponent as ArrowSvg } from "assets/vector/select-drop-down.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "constants/routes";

const DropdownIndicator = (props) => {
	return (
		<components.DropdownIndicator {...props}>
			<ArrowSvg />
		</components.DropdownIndicator>
	);
};
const ChangeDirection = ({
	setDecisionsData,
	decisionBox,
	decisionsData,
	programTopicId,
}) => {
	const { avatar, name, role } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
	const { id: programId } =
		useSelector((state) => state.userPrograms.singleProgram) || {};
	const { DECISION_FORM } = routes;
	const selectDirections = decisionBox.find(
		(box) => box.decision?.type === "selectDirections"
	);

	const feedbackForm = decisionBox.find(
		(box) => box.decision?.type === "selectDirections"
	)?.form_template;

	const selectOptions =
		selectDirections?.directions.map((direction) => ({
			value: direction.id,
			label: direction.name,
		})) || [];

	return (
		<>
			<div className={styles.selectTitle}>Change direction</div>
			<div className={styles.select_wrap}>
				<Select
					options={selectOptions}
					clearable={false}
					value={decisionsData.selectDirections || ""}
					placeholder={"Choose a program"}
					styles={customDecisionSelectStyles}
					components={{
						DropdownIndicator,
						IndicatorSeparator: () => null,
					}}
					onChange={(e) =>
						setDecisionsData((prev) => ({ ...prev, selectDirections: e }))
					}
				/>
			</div>
			<div className={styles.actions}>
				<Button
					className={`button button--orange-invert ${styles.button} decision`}
					onClick={() =>
						setDecisionsData((prev) => ({ ...prev, step: "selectDecision" }))
					}
				>
					Go back
				</Button>
				{feedbackForm ? (
					<Link
						to={{
							pathname: DECISION_FORM,
						}}
						state={{
							participant_program_id: programId,
							participant_program_topic_id: programTopicId,
							name,
							avatar,
							role,
							form: feedbackForm,
							decisionsData: {
								id: selectDirections.id,
								decision_id: selectDirections.decision.id,
								decision_program_id: selectDirections.decision_program.id,
								direction_id: decisionsData.selectDirections.value,
							},
						}}
						className={`button button--blue ${styles.button} decision`}
					>
						Fill out the form
					</Link>
				) : (
					<Button
						type="button"
						className={`button button--orange-invert ${styles.button} decision`}
						disabled
					>
						Fill out the form
					</Button>
				)}
			</div>
		</>
	);
};

export default ChangeDirection;

import React, { useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import routes from "constants/routes";
import isSurveyFilledById from "helpers/isSurveyFilledById";
import { useDispatch, useSelector } from "react-redux";
import { getInfoAboutCurrentUser } from "../../../redux/actions";
import InitLoader from "components/auth/initLoader/initLoader";

const LoginEnter = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const userInfoRequestId = useRef(null);
	const { authToken, mainCoach, userRole, surveyData } = useSelector(
		(state) => state.auth
	);
	const { requestId, main_coach, role, survey } =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
	const isMainCoachExist = main_coach || mainCoach;
	const isUserRoleExist = role || userRole;
	const isSurveyDataExist = surveyData || survey;
	const shouldGetUserInfo = authToken && isUserRoleExist !== "school_admin";
	const allowRender =
		!shouldGetUserInfo || requestId === userInfoRequestId.current;

	useEffect(() => {
		if (shouldGetUserInfo) {
			userInfoRequestId.current = Date.now().toString();
			dispatch(getInfoAboutCurrentUser(userInfoRequestId.current));
		}
	}, [shouldGetUserInfo]);

	const renderComponent = () => {
		const code = location.search.includes("code");
		const id = location.search.includes("id");
		const {
			DASHBOARD,
			QUESTIONNAIRE,
			PROGRAMME_STARTING,
			QUESTIONNAIRE_COACH_MATCHING,
		} = routes;

		if (isSurveyDataExist.skip) {
			return <Navigate to={DASHBOARD} />;
		}

		if (
			code &&
			id &&
			isSurveyDataExist.required &&
			isSurveyDataExist.filled &&
			isSurveyDataExist.all_filled?.length > 0 &&
			isSurveyFilledById(
				isSurveyDataExist.survey_id,
				isSurveyDataExist.all_filled
			)
		) {
			return <Navigate to={PROGRAMME_STARTING} />;
		}

		if (
			code &&
			id &&
			(isSurveyDataExist.required || isUserRoleExist === "coach") &&
			!isSurveyDataExist.filled
		) {
			return <Navigate to={QUESTIONNAIRE} />;
		}

		if (
			isUserRoleExist === "participant" &&
			!isMainCoachExist &&
			isSurveyDataExist.filled &&
			isSurveyDataExist.required
		) {
			return <Navigate to={QUESTIONNAIRE_COACH_MATCHING} />;
		}

		if (
			isUserRoleExist === "participant" &&
			!isMainCoachExist &&
			isSurveyDataExist.required &&
			!isSurveyDataExist.filled
		) {
			return <Navigate to={QUESTIONNAIRE} />;
		}

		return <Navigate to={DASHBOARD} />;
	};

	return allowRender ? renderComponent() : <InitLoader />;
};

export default LoginEnter;
